<h2 mat-dialog-title>{{title}}</h2>

<mat-dialog-content>

    <!-- sound details form -->
    <div class="dialog-column" *ngIf="dataType === 'sound'">
        <p><strong>{{data.Label}}</strong></p>
        <p>{{data.Description}}</p>
        <p>Uid: {{data.Uid}}</p>
        <p>Slug: {{data.Slug}}</p>
        <p>ImageUrl: {{data.ImageUrl}}</p>
        <p>FileInfo:<br/><span [innerHTML]="getFileInfoList(data)"></span></p>
    </div>

    <!-- order details form -->
    <div class="dialog-column" *ngIf="dataType === 'order'">
        <p><strong>{{data.order_id}}</strong></p>
        <p>Created: {{data.order_created | date:'medium'}}</p>
        <p>Updated: {{data.order_updated | date:'medium'}}</p>
        <p>Status: {{data.order_status}}</p>
        <p>Total: ${{data.order_total}} {{data.order_currency}}</p>
        <p>Promo: {{data.promo_id}}</p>
        <p>Skus:<span *ngFor="let sku of data.order_sku.split(',')"><br/>{{sku}}</span></p>
        <p><strong>Payer Info</strong></p>
        <p>Payer ID: {{data.payer_id}}</p>
        <p>Payer Name: {{data.payer_name}}</p>
        <p>Payer Email: {{data.payer_email}}</p>
        <p>User: {{data.user_id}}</p>
    </div>

    <!-- log details form -->
    <div class="dialog-column" *ngIf="dataType === 'log'">
        <p>URL ID: {{data.url_id}}</p>
        <p>User: {{data.user_id}}</p>
        <p>Date: {{data.date | date: 'medium'}}</p>
    </div>

    <!-- url details form -->
    <div class="dialog-column" *ngIf="dataType === 'url'">
        <p>URL ID: {{data.url_id}}</p>
        <p>URL: {{data.url}}</p>
        <p>Order Sku: {{data.order_sku}}</p>
        <p>Order ID: {{data.order_id}}</p>
        <p><strong>Stats</strong></p>
        <p>Clicks: {{data.clicks}}</p>
        <p>Credits: {{data.credits}}</p>
        <p>Updated: {{data.updated | date:'medium'}}</p>
        <p><strong>Created</strong></p>
        <p>Created: {{data.created | date:'medium'}}</p>
        <p>Expires: {{data.expires | date:'medium'}}</p>
    </div>

    <!-- promo details form -->
    <div class="dialog-column" *ngIf="dataType === 'promo'">
        <p>Promo: {{data.promo_id}}</p>
        <p>Value: {{data.value}}</p>
        <p>Expires: {{data.updated | date:'medium'}}</p>
        <p>Enabled: {{data.enabled}}</p>
        <p>Uses: {{data.uses}}</p>
    </div>
    
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="onClose()">Close</button>
</mat-dialog-actions>
