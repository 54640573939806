// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.child-content {
	width:100%;
	height:100%;
}
.center-container {
	width: 90%;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
}

table {
	width: 100%;
	margin-left:auto;
	margin-right:auto;
}

.mat-column-action {
	width: 24px;
	text-align: center;	
}

.mat-column-date {
	width: 135px;
	text-align: center;	
}

.mat-column-severity {
	width: 20px;
	padding: 10px;
	text-align: center;
}

.mat-column-severity .error {
	width: 100%;
	background-color: red;
}

.mat-column-severity .normal {
	width: 100%;
	background-color: transparent;
}

.mat-column-module {
	width: 75px;
	text-align: center;
}

.mat-column-message {
	max-width: 100%;
	padding-left: 16px;
	padding-right: 16px;
	word-break: break-all;
}

.page-buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.page-buttons > button {
    margin: 5px;
}

.filter-button {
	cursor: pointer;
	height: 100%;
	border: none;
	color: #3744AF;
	background-color: transparent;
	text-align: center;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
