import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// material
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon'
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { MatChipsModule as MatChipModule, MatChipsModule } from '@angular/material/chips';
import { MatExpansionModule } from '@angular/material/expansion';

//import 'hammerjs';

// table
import { CdkTableModule } from '@angular/cdk/table';

// toast
//import {ToastModule} from 'ng2-toastr/ng2-toastr';

// cookies
import { CookieModule } from 'ngx-cookie';

// auth ( https://github.com/ronzeidman/ng2-ui-auth )
import { AppConfig } from './AppConfig';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { MarketAuth } from './marketauth.service';

// auth protection
import { AuthGuard } from './auth.guard';

// service
import { SoundService } from './sound.service';
//import { ErrorService } from './error.service';

// app
import { AppComponent } from './app.component';
import { HomeComponent } from './home.component';
import { SoundsComponent } from './sounds.component';
import { CommentsComponent } from './comments.component';
import { UsersComponent } from './users.component';
import { TagsComponent } from './tags.component';
import { NotificationsComponent } from './notifications.component';
import { AdvancedComponent } from './advanced.component';
import { TokenComponent } from './token.component';
import { YesNoDialogComponent } from './yes-no-dialog.component';
import { TagAddDialogComponent } from './tag-add-dialog.component';
import { SoundEditDialogComponent } from './sound-edit-dialog.component';
import { SoundAttachDialogComponent } from './sound-attach-dialog.component';
import { SoundAddDialogComponent } from './sound-add-dialog.component';
import { UserEditDialogComponent } from './dialog/user-edit-dialog.component';
import { NotificationSendDialogComponent } from './notification-send-dialog.component';
import { SettingDialogComponent } from './settings/setting-dialog.component';
import { SettingsComponent } from './settings/settings.component';
import { SubscriptionsComponent } from './subscriptions.component';
import { StoreComponent } from './store/store/store.component';
import { TMApiService } from './store/tmapi.service';
import { StoreDetailsDialogComponent } from './store/store-details-dialog/store-details-dialog.component';
import { PromoDialogComponent } from './store/promo-dialog/promo-dialog.component';
import { PricingDialogComponent } from './store/pricing-dialog/pricing-dialog.component';
import { LogComponent } from './log/logs.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';

const routes: Routes = [
  { path: '',              component: HomeComponent },
  { path: 'sounds',        component: SoundsComponent, canActivate: [AuthGuard] },
  { path: 'comments',      component: CommentsComponent, canActivate: [AuthGuard] },
  { path: 'users',         component: UsersComponent, canActivate: [AuthGuard] },
  { path: 'tags',          component: TagsComponent, canActivate: [AuthGuard] },
  { path: 'notifications', component: NotificationsComponent, canActivate: [AuthGuard] },
  { path: 'settings',      component: SettingsComponent, canActivate: [AuthGuard] },
  { path: 'subscriptions', component: SubscriptionsComponent, canActivate: [AuthGuard] },
  { path: 'advanced',      component: AdvancedComponent, canActivate: [AuthGuard] },
  { path: 'token/:token',  component: TokenComponent },
  { path: 'store',         component: StoreComponent },
  { path: 'log',           component: LogComponent },
];

@NgModule({ declarations: [
        AppComponent, HomeComponent, SoundsComponent, CommentsComponent, UsersComponent, TagsComponent, NotificationsComponent,
        AdvancedComponent, TokenComponent, YesNoDialogComponent, TagAddDialogComponent, SoundEditDialogComponent, SoundAttachDialogComponent,
        SoundAddDialogComponent, UserEditDialogComponent, NotificationSendDialogComponent, SettingDialogComponent, SettingsComponent,
        SubscriptionsComponent, StoreComponent, StoreDetailsDialogComponent, PromoDialogComponent, PricingDialogComponent, LogComponent
    ],
    bootstrap: [AppComponent],
    exports: [RouterModule], imports: [BrowserModule, BrowserAnimationsModule, FormsModule, CdkTableModule,
        //ToastModule.forRoot(),
        RouterModule.forRoot(routes),
        CookieModule.forRoot(),
        MatListModule, MatSidenavModule, MatCardModule, MatMenuModule, MatDialogModule, MatTabsModule,
        MatToolbarModule, MatTableModule, MatButtonModule, MatInputModule, MatFormFieldModule,
        MatProgressBarModule, MatRadioModule, MatCheckboxModule, MatIconModule, MatSelectModule,
        MatOptionModule, MatChipsModule, MatExpansionModule,
        NgxChartsModule], providers: [SoundService, AuthGuard, MarketAuth, TMApiService, AppConfig, provideHttpClient(withInterceptorsFromDi())] })
export class AppModule { }
