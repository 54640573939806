/* ['Name', 'Value', 'Actions'] */

.mat-column-Name
{
  white-space: nowrap;
}

.mat-column-Value
{
  white-space: normal;
  word-break: break-all;
}