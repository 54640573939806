<h2 mat-dialog-title>
    Attach File
</h2>
<mat-dialog-content>
    <div>Attach a file to sound "{{sound.Label}}"</div>
    <br/>
    <input type="file" (change)="fileChange($event)" placeholder="Upload file">
    <br/><br/>
    <div>
        <div *ngIf="errorMsg.length > 0" style="color: red">{{errorMsg}}</div>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="onSubmit()">Attach</button>
    <button mat-raised-button mat-dialog-close color="warn">Cancel</button>
</mat-dialog-actions>