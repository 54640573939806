import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { SoundService } from './sound.service';
import { AppConfig } from './AppConfig';

import { User } from './user';
import { UserEditDialogComponent } from './dialog/user-edit-dialog.component';

// cdk table
import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';

@Component({
    selector: 'market-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.css'],
    standalone: false
})

export class UsersComponent implements OnInit
{
  constructor(
    private soundService: SoundService,
    public config: AppConfig,
    private dialog: MatDialog) { }

  // database table
  displayedColumns = ['Image', 'ID', 'Dates', 'Stats', 'Actions'];
  usersDatabase = new UsersDatabase();
  dataSource: UsersDataSource | null;

  //@ViewChild(MdTabGroup) tabControl: MdTabGroup;

  title = "Users"; 
  working = false;
  count = 0;
  search = "";
  lastSearch = "";
  lastSort = "";
  curOffset = 0;
  curLimit = 25;

  ngOnInit(): void
  {
    this.dataSource = new UsersDataSource(this.usersDatabase);
    this.getUsers();
  }

  getUsers(sort : string = ""): void
  {
    this.working = true;
    this.lastSort = sort;
    this.soundService.getAdminUsers(sort, this.curLimit, this.curOffset)
      .then(results => {
        this.setUsers(results);
      })
      .catch(err => this.setError(err));
  }

  getRolesString(row: User): string
  {
      let role = row.Role;

      if(role == 0) return "none";

      let ret = [];
      if (role & 2048) ret[ret.length] = "blocked";
      if (role & 1024) ret[ret.length] = "admin";
      if (role & 512) ret[ret.length] = "power";
      if (role & 64) ret[ret.length] = "no-chatter";
      if (role & 32) ret[ret.length] = "shadow";
      if (role & 16) ret[ret.length] = "subscriber";
      if (role & 8) ret[ret.length] = "moderator";
      if (role & 2) ret[ret.length] = "upload-mix";
      if (role & 4) ret[ret.length] = "upload-sound";
      if (role & 1) ret[ret.length] = "guest";

      return ret.join(" ");
  }


  getStringList(text: string): string
  {
    if (text && text.length > 0) return text.replace(/,/g, " ");
    else return "";
  }

  num(n : any) : number
  {
    if (n == undefined) { return 0; }
    return n;
  }

  editUser(row: any)
  {
    console.log("Edit user: " + row.Nickname + " (" + row.Uuid + ")");
    this.dialog.open(UserEditDialogComponent, { data: row })
      .afterClosed()
      .subscribe(result => {
        console.log("Result=" + JSON.stringify(result));
        if (result) this.getUsers();
      });
  }

  // called from toolbar sort buttons
  sortUsers(sort : string)
  {
    if (this.working)
    {
      console.error("Already getting users!");
      return;
    }

    // reset cur offset and get users
    this.lastSearch = "";
    this.curOffset = 0;
    this.getUsers(sort);
  }

  // called from toolbar search
  searchUsers(): void
  {
    if (this.working)
    {
      console.error("Already getting users!");
      return;
    }
    if (!this.search || this.search.length == 0)
    {
      // just get users by default if no search provided
      this.lastSearch = "";
      this.curOffset = 0;
      this.getUsers();
    }
    else
    {
      // reset offset to start
      this.curOffset = 0;
      this.getSearchUsers();
    }
  }

  getSearchUsers() : void
  {
    if (this.working)
    {
      console.error("Already getting users!");
      return;
    }
    if(!this.search)
    {
      console.error("No search term entered.");
      return;
    }
    console.log("Search users for query: " + this.search);
    this.working = true;
    this.count = 0;
    this.usersDatabase.dataChange.next([]);
    this.lastSort = "";
    this.lastSearch = "";

    // perform search and call setSounds with results
    this.soundService.getAdminSearchUsers(this.search, this.curLimit, this.curOffset)
      .then(users => { 
        this.lastSearch = this.search;
        return this.setUsers(users);
      })
      .catch(err => this.setError(err));
  }

  page(forward: boolean): void
  {
    this.curOffset += (forward ? 1 : -1) * this.curLimit;
    if (this.curOffset < 0) this.curOffset = 0;

    if (this.lastSearch)
    {
      this.getSearchUsers();
    }
    else
    {
      this.getUsers(this.lastSort);
    }
  }

    
  setUsers(users: User[])
  {
    this.working = false;
    if (users)
    {
      this.count = users.length;
      this.usersDatabase.dataChange.next(users);
    }
    else
    {
      this.count = 0;
      this.usersDatabase.dataChange.next([]);
    }
  }

  avatarError(image : any): void
  {
    //console.warn("Failed to load sound avatar");
    image.onerror = null;
    image.src = "/assets/default_user.jpg";
  }

  setError(err: any): void
  {
    this.working = false;
    console.info("Error during query!", err);
  }
}

export class UsersDatabase 
{
  /** Stream that emits whenever the data has been modified. */
  dataChange: BehaviorSubject<User[]> = new BehaviorSubject<User[]>([]);
  get data(): User[] { return this.dataChange.value; }
  
  constructor() 
  {
  }
}

export class UsersDataSource extends DataSource<any> 
{
  constructor(private _usersDatabase: UsersDatabase) 
  {
    super();
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<User[]> 
  {
    return this._usersDatabase.dataChange;
  }

  disconnect() {}
}