<mat-toolbar>
    <span>{{title}}</span>
    <span class="app-toolbar-filler"></span>
    <button mat-button (click)="addSetting()"><i class="material-icons">add</i> Add </button>    
</mat-toolbar>

<!-- [(selectedIndexChange)]="selectedIndex"  [(ngModel)]="tabs" -->
<mat-tab-group (selectedTabChange)="setTab($event.index)" id="tabs" name="tabs">
  <mat-tab label="Site Settings"></mat-tab>
  <mat-tab label="User Settings"></mat-tab>
</mat-tab-group>

<div class="database-container">
    <table mat-table #table [dataSource]="dataSource">
  
      <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->
  
      <ng-container matColumnDef="Name">
        <th mat-header-cell *matHeaderCellDef> Name </th>
        <td mat-cell *matCellDef="let row"> {{row.Name}} </td>
      </ng-container>
  
      <ng-container matColumnDef="Value">
        <th mat-header-cell *matHeaderCellDef> Value </th>
        <td mat-cell *matCellDef="let row"> {{row.Value}} </td>
      </ng-container>

      <ng-container matColumnDef="Type">
        <th mat-header-cell *matHeaderCellDef> Type </th>
        <td mat-cell *matCellDef="let row"> {{row.Type}} </td>
      </ng-container>

      <ng-container matColumnDef="Title">
        <th mat-header-cell *matHeaderCellDef> Title </th>
        <td mat-cell *matCellDef="let row"> {{row.Title}} </td>
      </ng-container>

      <ng-container matColumnDef="Description">
        <th mat-header-cell *matHeaderCellDef> Desc </th>
        <td mat-cell *matCellDef="let row"> {{row.Description}} </td>
      </ng-container>

      <ng-container matColumnDef="Default">
        <th mat-header-cell *matHeaderCellDef> Default </th>
        <td mat-cell *matCellDef="let row"> {{row.DefValue}} </td>
      </ng-container>

      <ng-container matColumnDef="Options">
        <th mat-header-cell *matHeaderCellDef> Options </th>
        <td mat-cell *matCellDef="let row"> {{row.Options}} </td>
      </ng-container>

      <ng-container matColumnDef="Zen">
        <th mat-header-cell *matHeaderCellDef> Zen </th>
        <td mat-cell *matCellDef="let row"> {{row.Zen}} </td>
      </ng-container>

      <ng-container matColumnDef="Min">
        <th mat-header-cell *matHeaderCellDef> Min </th>
        <td mat-cell *matCellDef="let row"> {{row.Min}} </td>
      </ng-container>

      <ng-container matColumnDef="Max">
        <th mat-header-cell *matHeaderCellDef> Max </th>
        <td mat-cell *matCellDef="let row"> {{row.Max}} </td>
      </ng-container>

      <ng-container matColumnDef="Step">
        <th mat-header-cell *matHeaderCellDef> Step </th>
        <td mat-cell *matCellDef="let row"> {{row.Step}} </td>
      </ng-container>

      <ng-container matColumnDef="Sort">
        <th mat-header-cell *matHeaderCellDef> Sort </th>
        <td mat-cell *matCellDef="let row"> {{row.Sort}} </td>
      </ng-container>

      <ng-container matColumnDef="Section">
        <th mat-header-cell *matHeaderCellDef> Section </th>
        <td mat-cell *matCellDef="let row"> {{row.Section}} </td>
      </ng-container>

      <ng-container matColumnDef="Regex">
        <th mat-header-cell *matHeaderCellDef> Regex </th>
        <td mat-cell *matCellDef="let row"> {{row.Regex}} </td>
      </ng-container>
     
      <!-- Actions Column -->
      <ng-container matColumnDef="Actions">
            <th mat-header-cell *matHeaderCellDef> Actions </th>
            <td mat-cell *matCellDef="let row">
                <button mat-button (click)="editSetting(row)"><i class="material-icons action-edit">mode_edit</i></button><br>                
            </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-progress-bar mode="indeterminate" *ngIf="working"></mat-progress-bar>
  </div>
  