import { Component, Inject } from "@angular/core";
import { SoundService } from "./sound.service";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Tag } from "./tag";

@Component({
    templateUrl: "./tag-add-dialog.component.html",
    standalone: false
})
export class TagAddDialogComponent
{
    public title = "Add Tag";

    public tag = new Tag();

    public edit = false;
    
    public badInput = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private soundService: SoundService,
        private dialogRef: MatDialogRef<TagAddDialogComponent>)
    {
        if (data)
        {
            if (data.edit && data.edit == true) 
            {
                this.title = "Edit Tag";
                this.edit = true;
            }
            if (data.tag)
            {
                console.log("Parsing tag");
                this.tag = data.tag;
            }
        }
    }

    reformatTagValue(): void
    {
        this.tag.Value = this.tag.Value.replace(/\s+/g, '').toLowerCase(); // remove whitespace, make lowercase
    }

    clearError(): void
    {
        this.badInput = false;
    }

    onSubmit(): void
    {
        if (!this.tag || !this.tag.Label || this.tag.Sort == 0)
        {
            this.badInput = true;
            return;
        }

        if (this.edit)
        {
            console.log("removing tag=" + this.tag.Value + ", label=" + this.tag.Label + ", sort=" + this.tag.Sort);
            
                        this.soundService.deleteAdminTag(this.tag.Value)
                            .then(tag => { 
                                console.log("tag deleted " + JSON.stringify(tag));

                                // now add it back
                                this.add();
                            })
                            .catch(error => {
                                console.error('An error occurred', error);                 
                                return Promise.reject(error.message || error);
                            });
        }
        else
        {
            this.add();
        }
    }

    add() : void
    {
        console.log("adding tag=" + this.tag.Value + ", label=" + this.tag.Label + ", sort=" + this.tag.Sort);
        
        this.soundService.addAdminTag(this.tag)
        .then(tag => { 
            console.log("tag added " + JSON.stringify(tag));
            this.dialogRef.close(tag);
        })
        .catch(error => {
            console.error('An error occurred', error);                 
            return Promise.reject(error.message || error);
        });

    }
}