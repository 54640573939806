/*  ['Order', 'LongDates', 'Data'] */

.mat-column-Order
{
  white-space: nowrap;
}

.mat-column-LongDates
{
  white-space: nowrap;
}

.mat-column-Data {
  white-space: normal;
  word-break: break-all;
}
