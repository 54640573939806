<mat-toolbar>
    <span>{{title}}</span>
    <span class="app-toolbar-filler"></span>
    <button mat-button (click)="flush()"><i class="material-icons">delete</i> Flush Cache </button>
  </mat-toolbar>

<textarea class="terminal terminal-text full-width" rows=25 readonly id="terminal" name="terminal" [(ngModel)]="terminal">
</textarea>

<mat-progress-bar mode="indeterminate" *ngIf="working"></mat-progress-bar>     
<form style="display: flex; align-items: center;">
  <mat-form-field style="flex: 1; margin-right: 8px;">
    <input matInput placeholder="Enter Command" name="cmd" [(ngModel)]="cmd" (keyup.enter)="submit()"/>
  </mat-form-field>
  <button mat-button (click)="submit()">Send</button>
</form>
