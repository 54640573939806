.child-content {
	width:100%;
	height:100%;
}

.center-container {
	width: 90%;
	margin-left: auto;
	margin-right: auto;
}

table {
	width: 100%;
	margin-left:auto;
	margin-right:auto;
}

.mat-column-action {
	width: 24px;
	max-width: 48px;
	padding: 0 0 0 8px;
}

.mat-column-date {
	width: 175px;
	max-width: 175px;
}

.mat-column-severity {
	width: 30px;
	text-align: top;
}

.mat-column-module {
	width: 75px;
}

.mat-column-message {
	max-width: 100%;
	padding-left: 16px;
	padding-right: 16px;
	word-break: break-all;
}

.page-buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.page-buttons > button {
    margin: 5px;
}

.filter-button {
	cursor: pointer;
	height: 100%;
	border: none;
	color: #3744AF;
	background-color: transparent;
}