<h2 mat-dialog-title>
    {{ title }}
</h2>
<mat-dialog-content>
    <div fxLayout="column">
        <div>
            <mat-form-field>
                <mat-label>Tag</mat-label>
                <input matInput value="" [(ngModel)]="tag.Value" (keyup)="reformatTagValue()" (input)="clearError()" required [disabled]="edit">
            </mat-form-field>
        </div>
        <div>
            <mat-form-field>
                <mat-label>Display Value</mat-label>
                <input matInput value="" [(ngModel)]="tag.Label" (input)="clearError()" required>
            </mat-form-field>
        </div>
        <div>
            <mat-form-field>
                <mat-label>Sort Order Number</mat-label>
                <input matInput value="" type="number" [(ngModel)]="tag.Sort" (input)="clearError()" required>
            </mat-form-field>
        </div>
        <div class="flex-stretch">
            <mat-form-field>
                <mat-label>Description</mat-label>
                <textarea matInput rows="5" value="" [(ngModel)]="tag.Description" (input)="clearError()"></textarea>
            </mat-form-field>
        </div>  
        <div>
            <mat-form-field>
                <mat-label>Image URL</mat-label>
                <input matInput value="" [(ngModel)]="tag.ImageUrl" (input)="clearError()">
            </mat-form-field>
        </div>
        <div *ngIf="badInput" style="color:red; font-weight:600">
            Please fill out all required fields.
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="onSubmit()" *ngIf="edit == false">Add Tag</button>
    <button mat-raised-button color="primary" (click)="onSubmit()" *ngIf="edit == true">Edit Tag</button>
    <button mat-raised-button mat-dialog-close color="warn">Cancel</button>
</mat-dialog-actions>