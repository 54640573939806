<mat-toolbar>
    <span>MP3 Store</span>
    <span class="app-toolbar-filler"></span>
    <form class="app-toolbar-form ">
        <input matInput placeholder="ID, Url, User" class="app-toolbar-search" name="search" [(ngModel)]="search">
        <button mat-button (click)="onSearch()">
          <i class="material-icons">search</i>
        </button>
    </form>
</mat-toolbar>

<!-- [(selectedIndexChange)]="selectedIndex"  [(ngModel)]="tabs" -->
<mat-tab-group (selectedTabChange)="setTab($event.index)" id="tabs" name="tabs">
      <mat-tab label="Sounds"></mat-tab>
      <mat-tab label="Pricing"></mat-tab>
      <mat-tab label="Orders"></mat-tab>
      <mat-tab label="Download Log"></mat-tab>
      <mat-tab label="URLs"></mat-tab>
      <mat-tab label="Promo"></mat-tab>
</mat-tab-group>

<mat-progress-bar mode="indeterminate" *ngIf="this.working"></mat-progress-bar>
<br/>
<div id="content">
    <div class="child-content" >
        <button *ngIf="this.selectedTab==0" mat-button (click)="onSoundSync()"><i class="material-icons">sync</i> Sync </button>
        <button *ngIf="this.selectedTab==5" mat-button (click)="onAddPromo()"><i class="material-icons">add</i> Add </button>
        <button *ngIf="this.selectedTab==1" mat-button (click)="onAddPrice()"><i class="material-icons">add</i> Add </button>
        <div class="center-container" *ngIf="!this.working && count <= 0">
            <p>No data.</p>
        </div>
        <div *ngIf="!this.working && count > 0">
            <div class="page-buttons" *ngIf="!working">
                <button mat-raised-button (click)="page(false)" [disabled]="offset == 0 && !paging">Prev</button>
                <button mat-raised-button (click)="page(true)" [disabled]="count < limit && !paging">Next</button>
            </div>

            <table mat-table #table [dataSource]="this.data">
                <ng-container matColumnDef="sound_id">
                    <th mat-header-cell *matHeaderCellDef>Uid</th>
                    <td mat-cell *matCellDef="let item">{{item.Uid}}</td>
                </ng-container>

                <ng-container matColumnDef="sound_slug">
                    <th mat-header-cell *matHeaderCellDef>Slug</th>
                    <td mat-cell *matCellDef="let item">{{item.Slug}}</td>
                </ng-container>

                <ng-container matColumnDef="sound_label">
                    <th mat-header-cell *matHeaderCellDef>Label</th>
                    <td mat-cell *matCellDef="let item">{{item.Label}}</td>
                </ng-container>

                <ng-container matColumnDef="sound_status">
                    <th mat-header-cell *matHeaderCellDef>File Status</th>
                    <td mat-cell *matCellDef="let item">
                        <span style="color: red;" *ngIf="!this.tmapiService.haveFileInfo(item)">[ !! ]</span>
                        <span style="color: green;" *ngIf="this.tmapiService.haveFileInfo(item)">[ OK ]</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="pricing_duration">
                    <th mat-header-cell *matHeaderCellDef>Duration</th>
                    <td mat-cell *matCellDef="let item">{{item.duration}}</td>
                </ng-container>

                <ng-container matColumnDef="pricing_price">
                    <th mat-header-cell *matHeaderCellDef>Price</th>
                    <td mat-cell *matCellDef="let item">{{item.price}}</td>
                </ng-container>

                <ng-container matColumnDef="order_id">
                    <th mat-header-cell *matHeaderCellDef>Order Id</th>
                    <td mat-cell *matCellDef="let item">{{item.order_id}}</td>
                </ng-container>

                <ng-container matColumnDef="order_date">
                    <th mat-header-cell *matHeaderCellDef>Date</th>
                    <td mat-cell *matCellDef="let item">{{this.getBestOrderDate(item) | date:'medium'}}</td>
                </ng-container>

                <ng-container matColumnDef="order_name">
                    <th mat-header-cell *matHeaderCellDef>Name</th>
                    <td mat-cell *matCellDef="let item">{{item.payer_name}}</td>
                </ng-container>

                <ng-container matColumnDef="order_email">
                    <th mat-header-cell *matHeaderCellDef>Email</th>
                    <td mat-cell *matCellDef="let item">{{item.payer_email}}</td>
                </ng-container>

                <ng-container matColumnDef="order_user">
                    <th mat-header-cell *matHeaderCellDef>User Id</th>
                    <td mat-cell *matCellDef="let item"><a href="{{this.config.host()}}/user/{{item.user_id}}" target="_blank">{{item.user_id}}</a></td>
                </ng-container>

                <ng-container matColumnDef="order_total">
                    <th mat-header-cell *matHeaderCellDef>Total</th>
                    <td mat-cell *matCellDef="let item">${{item.order_total}}</td>
                </ng-container>

                <ng-container matColumnDef="order_skus">
                    <th mat-header-cell *matHeaderCellDef>Skus</th>
                    <td mat-cell *matCellDef="let item">{{item.order_sku}}</td>
                </ng-container>

                <!-- id Column -->
                <ng-container matColumnDef="log_id">
                    <th mat-header-cell *matHeaderCellDef>ID</th>
                    <td mat-cell *matCellDef="let item">{{item.id}}</td>
                </ng-container>

                <!-- url Column -->
                <ng-container matColumnDef="log_url">
                    <th mat-header-cell *matHeaderCellDef>URL</th>
                    <td mat-cell *matCellDef="let item">{{item.url_id}}</td>
                </ng-container>

                <!-- user Column -->
                <ng-container matColumnDef="log_user">
                    <th mat-header-cell *matHeaderCellDef>User</th>
                    <td mat-cell *matCellDef="let item"><a href="{{this.config.host()}}/user/{{item.user_id}}" target="_blank">{{item.user_id}}</a></td>
                </ng-container>

                <!-- date Column -->
                <ng-container matColumnDef="log_date">
                    <th mat-header-cell *matHeaderCellDef>Date</th>
                    <td mat-cell *matCellDef="let item">{{item.date | date:'medium'}}</td>
                </ng-container>

                 <ng-container matColumnDef="url_id">
                  <th mat-header-cell *matHeaderCellDef>URL ID</th>
                  <td mat-cell *matCellDef="let item">{{item.url_id}}</td>
                </ng-container>

                <ng-container matColumnDef="url_url">
                    <th mat-header-cell *matHeaderCellDef>URL</th>
                    <td mat-cell *matCellDef="let item">{{item.url}}</td>
                </ng-container>

                <ng-container matColumnDef="url_order_id">
                    <th mat-header-cell *matHeaderCellDef>Order ID</th>
                    <td mat-cell *matCellDef="let item">{{item.order_id}}</td>
                </ng-container>

                <ng-container matColumnDef="url_created">
                    <th mat-header-cell *matHeaderCellDef>Created</th>
                    <td mat-cell *matCellDef="let item">{{item.created | date:'medium'}}</td>
                </ng-container>

                <ng-container matColumnDef="url_expires">
                    <th mat-header-cell *matHeaderCellDef>Expires</th>
                    <td mat-cell *matCellDef="let item">{{item.expires | date:'medium'}}</td>
                </ng-container>

                <ng-container matColumnDef="url_updated">
                    <th mat-header-cell *matHeaderCellDef>Updated</th>
                    <td mat-cell *matCellDef="let item">{{item.updated | date:'medium'}}</td>
                </ng-container>

                <ng-container matColumnDef="url_credits">
                    <th mat-header-cell *matHeaderCellDef>Credits</th>
                    <td mat-cell *matCellDef="let item">{{item.credits}}</td>
                </ng-container>

                <ng-container matColumnDef="url_clicks">
                    <th mat-header-cell *matHeaderCellDef>Clicks</th>
                    <td mat-cell *matCellDef="let item">{{item.clicks}}</td>
                </ng-container>

                <ng-container matColumnDef="download">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let item">
                        <form ngNoForm action="{{this.tmapiService.getApiDownloadUrl()}}" target="_blank" method="POST">
                            <input type="hidden" id="downloadId" name="downloadId" value="{{item.url_id}}" />
                            <input type="hidden" id="token" name="token" value="{{this.tmapiService.getToken()}}" />
                            <button class="download-button" type="submit" mat-button>Download</button>
                        </form>
                    </td>
                </ng-container>

                <ng-container matColumnDef="details">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let item"><button mat-button (click)="onDetails(item)">View Details</button></td>
                </ng-container>

                <ng-container matColumnDef="promo_id">
                    <th mat-header-cell *matHeaderCellDef>Promo</th>
                    <td mat-cell *matCellDef="let item">{{item.promo_id}}</td>
                  </ng-container>

                <ng-container matColumnDef="discount">
                    <th mat-header-cell *matHeaderCellDef>Value</th>
                    <td mat-cell *matCellDef="let item">{{item.value}}</td>
                </ng-container>

                <ng-container matColumnDef="expires">
                    <th mat-header-cell *matHeaderCellDef>Expires</th>
                    <td mat-cell *matCellDef="let item">{{item.expires | date:'medium'}}</td>
                </ng-container>

                <ng-container matColumnDef="enabled">
                    <th mat-header-cell *matHeaderCellDef>Enabled</th>
                    <td mat-cell *matCellDef="let item">{{item.enabled}}</td>
                </ng-container>

                <ng-container matColumnDef="uses">
                    <th mat-header-cell *matHeaderCellDef>Uses</th>
                    <td mat-cell *matCellDef="let item">{{item.uses}}</td>
                </ng-container>

                <!-- Actions Column -->
                <ng-container cdkColumnDef="edit">
                    <th mat-header-cell *cdkHeaderCellDef></th>
                    <td mat-cell *cdkCellDef="let item">
                        <button mat-button (click)="onEdit(item)"><i class="material-icons action-edit">mode_edit</i></button><br>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="getColumnIds()"></tr>
                <tr mat-row *matRowDef="let row; columns: getColumnIds();"></tr>
            </table>

            <div class="page-buttons" *ngIf="!working">
                <button mat-raised-button (click)="page(false)" [disabled]="offset == 0 && !paging">Prev</button>
                <button mat-raised-button (click)="page(true)" [disabled]="count < limit && !paging">Next</button>
            </div>
        </div>
    </div>
</div>
