import { Component, Inject } from "@angular/core";
import { SoundService } from "../sound.service";
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { Setting } from "./setting";

@Component({    
    templateUrl: "./setting-dialog.component.html",
})
export class SettingDialogComponent
{
    public title = "Add Setting";
    public type = 0;
    public setting = new Setting();
    public edit = false;
    public badInput = false;
    // current supported option types
    settingTypes = [
        { value: "avatar", label: "Avatar" },
        { value: "text", label: "Text" },
        { value: "url", label: "Url" },
        { value: "list", label: "List" },
        { value: "radio", label: "Radio" },
        { value: "bool", label: "Bool" },
        { value: "number", label: "Number" },
        { value: "range", label: "Range" },
        { value: "delete", label: "Delete" },

    ];

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private soundService: SoundService,
        private dialogRef: MatDialogRef<SettingDialogComponent>)
    {
        if (data)
        {
            this.type = data.type;
            if (data.edit && data.edit == true) 
            {
                this.title = "Edit Setting";
                this.edit = true;
            }
            if (data.setting)
            {
                this.setting.Name = data.setting.Name;
                this.setting.Value = data.setting.Value;
                this.setting.Sort = data.setting.Sort;
                this.setting.Section = data.setting.Section;
                this.setting.Type = data.setting.Type;
                this.setting.Title = data.setting.Title;
                this.setting.Description = data.setting.Description;
                this.setting.DefValue = data.setting.DefValue;
                this.setting.Options = data.setting.Options;
                this.setting.Zen = data.setting.Zen;
                this.setting.Min = data.setting.Min;
                this.setting.Max = data.setting.Max;
                this.setting.Step = data.setting.Step;
                this.setting.Regex = data.setting.Regex;
            }
        }
    }

    clearError(): void
    {
        this.badInput = false;
    }

    onRemove(): void
    {
        if (!this.setting || !this.setting.Name)
        {
            this.badInput = true;
            return;
        }

        // removing is only supported on type 1 / user settings for now
        if (this.type == 1)
        {
            this.soundService.deleteAdminUserSetting(this.setting)
            .then(results => { 
                console.log("setting removed " + JSON.stringify(results));
                this.dialogRef.close(this.setting);
            })
            .catch(error => {
                console.error('An error occurred', error);                 
                return Promise.reject(error.message || error);
            });
        }
    }

    onSubmit(): void
    {
        if (!this.setting || !this.setting.Name)
        {
            this.badInput = true;
            return;
        }

        console.log("adding setting name=" + this.setting.Name + " value=" + this.setting.Value);
        
        if (this.type == 0)
        {
            this.soundService.putAdminSetting(this.setting)
            .then(settings => { 
                console.log("setting added " + JSON.stringify(settings));
                this.dialogRef.close(settings);
            })
            .catch(error => {
                console.error('An error occurred', error);                 
                return Promise.reject(error.message || error);
            });
        }
        else
        {
            this.soundService.putAdminUserSetting(this.setting)
            .then(settings => { 
                console.log("setting added " + JSON.stringify(settings));
                this.dialogRef.close(settings);
            })
            .catch(error => {
                console.error('An error occurred', error);                 
                return Promise.reject(error.message || error);
            });
        }

    }
}