<mat-toolbar>
  <span>{{title}}</span>
  <span>&nbsp;({{offset}}-{{offset+count}})</span>
  <span class="app-toolbar-filler"></span>
</mat-toolbar>

<mat-progress-bar mode="indeterminate" *ngIf="working"></mat-progress-bar>

<div class="page-buttons" *ngIf="count > 0">
  <button mat-raised-button (click)="page(false)" [disabled]="offset == 0 && !paging">Prev</button>
  <button mat-raised-button (click)="page(true)" [disabled]="count < limit && !paging">Next</button>
</div>

<div class="database-container">
  <table mat-table #table [dataSource]="dataSource">

    <!-- Order Column -->
    <ng-container matColumnDef="Order">
      <th mat-header-cell *matHeaderCellDef> Order </th>
      <td mat-cell *matCellDef="let row">
        <span class="text-muted">User:</span><br>
        <a href="{{this.config.host()}}/user/{{row.UserId}}" target="market">{{row.UserId}}</a><br>
        <span class="text-muted">Store:</span><br>
        {{row.Store}}<br>
        <span class="text-muted">Product:</span><br>
        {{row.ProductId}}<br>
        <span class="text-muted">Order:</span><br>
        {{row.OrderId}}<br><br><br>
      </td>
    </ng-container>

    <!-- Dates Column -->
    <ng-container matColumnDef="LongDates">
        <th mat-header-cell *matHeaderCellDef> Dates </th>
        <td mat-cell *matCellDef="let row">
          <span class="text-muted">Created:</span><br>
          {{row.Created}}<br>
          <span class="text-muted">Purchased:</span><br>
          {{row.Purchased}}<br>
          <span class="text-muted">Expires:</span><br>
          {{row.Expires}}<br><br><br>
        </td>
      </ng-container>

    <!-- Data Column -->
    <ng-container matColumnDef="Data">
      <th mat-header-cell *matHeaderCellDef>Data</th>
      <td mat-cell *matCellDef="let row">{{row.Data}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>

<div class="page-buttons" *ngIf="count > 0">
  <button mat-raised-button (click)="page(false)" [disabled]="offset == 0 && !paging">Prev</button>
  <button mat-raised-button (click)="page(true)" [disabled]="count < limit && !paging">Next</button>
</div> 