<h2 mat-dialog-title>
    Send Notification
</h2>
<mat-dialog-content>
    <h4>Topic</h4>
    <hr>
    <mat-radio-group name="topic" [(ngModel)]="topic" [disabled]="formDisabled" required>
        <mat-radio-button value="dev">Dev</mat-radio-button>
        <mat-radio-button value="admin">Admin</mat-radio-button>
        <mat-radio-button value="all">All Users</mat-radio-button>
    </mat-radio-group>
    <br>
    <h4>Message</h4>
    <hr>
    <div>
        <mat-form-field>
            <textarea matInput rows="5" placeholder="Message:" value="" [(ngModel)]="message" [disabled]="formDisabled"></textarea>
        </mat-form-field>
    </div>
    <h4>Payload</h4>
    <hr>  
    <div>
        <mat-radio-group name="payload" [(ngModel)]="payload" [disabled]="formDisabled" required>
            <mat-radio-button value="sound">Sound</mat-radio-button>
            <mat-radio-button value="user">User</mat-radio-button>
            <mat-radio-button value="custom">Custom</mat-radio-button>
        </mat-radio-group>
        <br/>
        <mat-form-field *ngIf="this.payload === 'sound'">
            <input matInput placeholder="Sound Uid:" value="" [(ngModel)]="uid" [disabled]="formDisabled">
        </mat-form-field>
        <mat-form-field *ngIf="this.payload === 'user'">
            <input matInput placeholder="User uid:" value="" [(ngModel)]="uid" [disabled]="formDisabled">
        </mat-form-field>
        <mat-form-field *ngIf="this.payload === 'custom'">
            <input matInput placeholder="Uri:" value="" [(ngModel)]="uid" [disabled]="formDisabled">
        </mat-form-field>

    </div>
        
    <div class="message" *ngIf="sendingMessage" [innerHtml]="sendingMessage">
        Sending...<br>
        Message-Type: DEV<br>
        Message: This is a test message!
    </div>
    <div class="message" *ngIf="endpointMessage" [innerHtml]="endpointMessage">
        Notification sent to endpoint at: 0987654321-1234567890-0987654321
    </div>
    <div class="message error" *ngIf="errorMessage" [innerHtml]="errorMessage">
        Could not send message: no response from server!
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="onSubmit()" [disabled]="formDisabled">Send</button>
    <button mat-raised-button [mat-dialog-close]="dialogResult" color="warn">{{formDisabled ? "Close" : "Cancel"}}</button>
</mat-dialog-actions>