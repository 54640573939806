<mat-toolbar>
  <span>
    {{title}}
    <span *ngIf="lastSearch.length"> with {{lastSearch}} </span>
    <span *ngIf="!lastSearch.length && lastSort.length"> by {{lastSort}} </span>
    <span *ngIf="count > 0">({{curOffset + 1}} - {{(curOffset + count)}})</span>
    <span *ngIf="count == 0">(0)</span>
  </span>

  <button mat-button (click)="sortUsers('')" title="Sort by Newest Users">New</button>
  <button mat-button (click)="sortUsers('LastLogin')" title="Sort by Last Login">Login</button>
  <button mat-button (click)="sortUsers('Subscription')" title="Sort by Subscription">Subscriber</button>
   |
  <button mat-button class="small-button" (click)="sortUsers('Zen')" title="Sort by Zen"><i class="material-icons">diamond</i></button>
  <button mat-button class="small-button" (click)="sortUsers('MyHearts')" title="Sort by Hearts Sent by User"><i class="material-icons" style="vertical-align: middle;">favorite</i></button>
  <button mat-button class="small-button" (click)="sortUsers('MyComments')" title="Sort by Comments Written by User"><i class="material-icons" style="vertical-align: middle;">comments</i></button>
  <button mat-button class="small-button" (click)="sortUsers('MyDownloads')" title="Sort by Downloads by User"><i class="material-icons" style="vertical-align: middle;">download</i></button>
  <button mat-button class="small-button" (click)="sortUsers('MyMinutes')" title="Sort by Minutes Listened by User"><i class="material-icons" style="vertical-align: middle;">headphones</i></button>
  |
  <button mat-button class="small-button" (click)="sortUsers('MyUploads')"><i class="material-icons" title="Sort by Uploads by User" style="vertical-align: middle;">upload</i></button>
  <button mat-button class="small-button" (click)="sortUsers('Hearts')"><i class="material-icons" title="Sort by Hearts for User Uploaded Sounds" style="vertical-align: middle;">favorite</i></button>
  <button mat-button class="small-button" (click)="sortUsers('Comments')"><i class="material-icons" title="Sort by Comments Written for User Sounds" style="vertical-align: middle;">comments</i></button>
  <button mat-button class="small-button" (click)="sortUsers('Downloads')"><i class="material-icons" title="Sort by Downloads for User Sounds" style="vertical-align: middle;">download</i></button>
  <button mat-button class="small-button" (click)="sortUsers('Minutes')"><i class="material-icons" title="Sort by Minutes Listened for User Sounds" style="vertical-align: middle;">headphones</i></button>

  <span class="app-toolbar-filler"></span>

  <!-- <button mat-button><i class="material-icons">edit</i> Modify </button> -->

  <form class="app-toolbar-form ">
      <input matInput placeholder="ID, Email, Date" class="app-toolbar-search" name="search" [(ngModel)]="search">
      <button mat-button (click)="searchUsers()">
        <i class="material-icons">search</i>
      </button>
  </form>
</mat-toolbar>

<div class="database-container">
  <table mat-table #table [dataSource]="dataSource">

    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

    <!-- Image Column -->
    <ng-container matColumnDef="Image">
      <th mat-header-cell *matHeaderCellDef>Avatar</th>
      <td mat-cell *matCellDef="let row"> <a href="{{this.config.host()}}/user/{{row.Uuid}}" target="market"><img [src]="row.UserAvatarUrl || row.AvatarUrl" (error)="avatarError($event.target);" width=64 height=64></a></td>
    </ng-container>

    <!-- ID Column (Email and Uuid) -->
    <ng-container matColumnDef="ID">
      <th mat-header-cell *matHeaderCellDef>User Info</th>
      <td mat-cell *matCellDef="let row"><strong>{{row.NameFirst}} {{row.NameLast}}</strong> <span *ngIf="row.UserName"> ( {{row.UserName}} )</span><br>
        {{row.Email}}<br>
        <span class="text-muted">ID: {{row.Uuid}}</span><br>
        <span *ngIf="row.Fid" class="text-muted">
          <a href="https://www.facebook.com/app_scoped_user_id/{{row.Fid}}/" target="market">Facebook</a>&nbsp;
        </span>
        <span *ngIf="row.Gid" class="text-muted">
          <a href="https://plus.google.com/{{row.Gid}}" target="market">Google</a>&nbsp;
        </span>
        <span *ngIf="row.FirebaseId" class="text-muted">
          <a href="https://console.firebase.google.com/project/whitenoisemarket/authentication/users?id={{row.FirebaseId}}" target="market">Firebase</a>&nbsp;
        </span>
      </td>
    </ng-container>

    <!-- Name Column (Nickname, First, Last) -->
    <!--
    <ng-container matColumnDef="Name">
      <th mat-header-cell *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let row">{{row.Nickname}}<br/> <span class="text-muted">{{row.NameFirst}} {{row.NameLast}}</span>      </td>
    </ng-container>
    -->

    <!-- Dates Column (Created, Last Login) -->
    <ng-container matColumnDef="Dates">
      <th mat-header-cell *matHeaderCellDef>Dates</th>
      <td mat-cell *matCellDef="let row">
        <span class="text-muted date-label">Login:</span> <span class="date">{{row.LastLogin.substring(0,10)}}</span><br>
        <span class="text-muted date-label">Created:</span> <span class="date">{{row.Created.substring(0,10)}}</span><br>
        <span *ngIf="row.Subscription && row.Subscription.substring(0,4) > '0001'">
          <span class="text-muted date-label">Subscription:</span> <span class="date">{{row.Subscription.substring(0,10)}}</span><br>
        </span>
        <!--<span class="text-muted date-label">Birth:</span> <span class="date">{{row.BirthDate.substring(0,10)}}</span>-->
      </td>
    </ng-container>

    <!-- Stats Column -->
    <ng-container matColumnDef="Stats">
      <th mat-header-cell *matHeaderCellDef>Stats</th>
      <td mat-cell *matCellDef="let row">
        <span class="text-muted" title="User roles">Roles:</span> {{getRolesString(row)}} (<strong>{{row.Role}}</strong>)<br>
        <span class="text-muted" title="Apps installed by user">Apps: </span> {{getStringList(row.Apps)}}<br>
        <span class="text-muted" title="Badges awarded to user">Badges: </span> {{getStringList(row.Badges)}}<br>
        <span *ngIf="row.Zen || row.MyHearts || row.MyComments || row.MyDownloads || row.MyMinutes">
          <span class="text-muted" title="User stats based on user taking actions like hearting things">Profile: </span>
          <i class="material-icons text-muted text-fixed" style="vertical-align: middle;">diamond</i> {{row.Zen || 0 | number}}
          <i class="material-icons text-muted text-fixed" style="vertical-align: middle;">favorite</i> {{row.MyHearts || 0 | number}}
          <i class="material-icons text-muted text-fixed" style="vertical-align: middle;">comments</i> {{row.MyComments || 0 | number}}
          <i class="material-icons text-muted text-fixed" style="vertical-align: middle;">download</i> {{row.MyDownloads || 0 | number}}
          <i class="material-icons text-muted text-fixed" style="vertical-align: middle;">headphones</i> {{row.MyMinutes || 0 | number}}
          <br>
        </span>
        <span *ngIf="row.MyUploads">
          <span class="text-muted" title="Sound stats for user's uploaded sounds">Uploads:</span>
          <i class="material-icons text-muted text-fixed" style="vertical-align: middle;">upload</i> {{row.MyUploads || 0 | number}}
          <i class="material-icons text-muted text-fixed" style="vertical-align: middle;">favorite</i> {{row.Hearts || 0 | number}}
          <i class="material-icons text-muted text-fixed" style="vertical-align: middle;">comments</i> {{row.Comments || 0 | number}}
          <i class="material-icons text-muted text-fixed" style="vertical-align: middle;">download</i> {{row.Downloads || 0 | number}}
          <i class="material-icons text-muted text-fixed" style="vertical-align: middle;">headphones</i> {{row.Minutes || 0 | number}}
        </span>
      </td>
    </ng-container>

    <!-- Actions Column -->
    <ng-container matColumnDef="Actions">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let row">
              <button mat-button (click)="editUser(row)"><i class="material-icons action-edit">mode_edit</i> Edit</button><br>
              <!-- <button mat-button (click)="attachSound(row)"><i class="material-icons action-attach">attach_file</i></button><br>
              <button mat-button (click)="deleteSound(row)"><i class="material-icons action-delete">delete</i></button><br> -->
                </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-progress-bar mode="indeterminate" *ngIf="working"></mat-progress-bar>

</div>
<div class="page-buttons" *ngIf="!working">
  <button mat-raised-button (click)="page(false)" [disabled]="curOffset == 0">Prev</button>
  <button mat-raised-button (click)="page(true)" [disabled]="count < curLimit">Next</button>
</div>