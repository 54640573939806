import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

// a reusable Yes/No dialog which returns result of true for Yes, false for No, undefined for clicking outside to dismiss.
// can be setup with optional data like MdDialog.open(YesNoDialogComponent, { data: {...} }) where:
//      data.title defines a dialog title (default none)
//      data.message defines the dialog message (default "Are you sure?")
//      data.yes defines the text to appear in the Yes button (default "Yes")
//      data.no defines the text to appear in the Yes button (default "No")

@Component({
    templateUrl: "./yes-no-dialog.component.html",
    standalone: false
})
export class YesNoDialogComponent
{
    constructor(@Inject(MAT_DIALOG_DATA) public data: any) 
    { 
        if (data)
        {
            if (data.title) this.title = data.title;
            if (data.message) this.message = data.message;
            if (data.yes) this.yesLabel = data.yes;
            if (data.no) this.noLabel = data.no;    
        }
    }

    public title = "";
    public message = "Are you sure?";
    public yesLabel = "Yes";
    public noLabel = "No";
}