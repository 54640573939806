
.app-toolbar-search 
{
  height: 100%;
  color: #333;
  border: 1px solid #D0D0D0;
  border-radius: 100px;
  background-color: #fafafa;
  padding: 5px 16px;

  flex: 1 1 auto;

  min-width: 120px;
  max-width: 300px;
  width: auto;

  outline: none;

  font: 13.333px Arial;
}

/* fixes for material breaking the search toolbar on some pages */
.app-toolbar-search::placeholder { 
  color: #757575 !important;
  opacity: 1 !important;
}
.app-toolbar-search::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #757575 !important;
  opacity: 1 !important;
}
.app-toolbar-search::-moz-placeholder { /* Firefox 19+ */
  color: #757575 !important;
  opacity: 1 !important;
}
.app-toolbar-search:-ms-input-placeholder { /* IE 10+ */
  color: #757575 !important;
  opacity: 1 !important;
}

.all-tab-link
{
    display: flex;
    flex-direction: row; 
    justify-content: center; 
    align-items: center; 
    width: 100%;
    height: 100%; 
    text-decoration: none;
    color: inherit;
}

.page-buttons
{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.page-buttons > button
{
    margin: 5px;
}

.mat-column-Image
{
    width: 80px;
    max-width: 80px;
}
.mat-column-Sound 
{
  min-width: 250px;
}
.mat-column-Dates 
{
    width: 150px;
    max-width: 150px;
}
.mat-column-Stats
{
    min-width: 50px;
}
.mat-column-Tags
{
    max-width: 150px;
    padding: 5px;
}
.mat-column-Actions
{
    max-width: 80px;
}
.sort-form 
{
    margin: 16px;
    font-size: 10pt;
    max-width: 100px;
}