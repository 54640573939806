import { Component, OnInit } from '@angular/core';
import { AppConfig } from './AppConfig';
import { MatDialog } from '@angular/material/dialog';

// cdk table
import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject, Observable } from 'rxjs';

// dialog
import { NotificationSendDialogComponent } from './notification-send-dialog.component';

// service
import { SoundService } from './sound.service';

// data
import { Note } from './note';

@Component({
    selector: 'market-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.css'],
    standalone: false
})

export class NotificationsComponent implements OnInit
{
    title = "Notifications";
    working = false;

    // data table
    displayedColumns = ['Image', 'Sent', 'Note'];
    database = new NoteDatabase();
    dataSource: NoteDataSource | null;
  
    constructor(private soundService: SoundService,
                private config: AppConfig,
                private dialog: MatDialog) {
    }

    ngOnInit(): void
    {
      this.working = true;
      this.dataSource = new NoteDataSource(this.database);
      this.soundService.getAdminNotifications().then(data => this.setData(data)).catch(err => this.setError(err));
    }
  
    sendNotification(): void
    {
      let dialogRef = this.dialog.open(NotificationSendDialogComponent);

      dialogRef.afterClosed().subscribe(result => {
          // using dialogRef instead of result allows us to check whether note was sent, even if they light-dismiss the dialog
          if (dialogRef.componentInstance.dialogResult)
          {
            this.soundService.getAdminNotifications()
              .then(data => this.setData(data))
              .catch(err => this.setError(err));
          }
        });
    }

    setData(data: Note[])
    {
      this.working = false;
      this.database.dataChange.next(data);
    }
  
    setError(err: any)
    {
      console.info("Error during query!");
      this.working = false;
    }

    getMarketWebsiteLink(row: Note): string
    {
      let url = row.Url;
      if(!url) return "";
      url = url.replace("whitenoisemarket://", this.config.host());
      return url;
    }
}

export class NoteDatabase 
{
  dataChange: BehaviorSubject<Note[]> = new BehaviorSubject<Note[]>([]);
  get data(): Note[] { return this.dataChange.value; }
  
  constructor() {}
}

export class NoteDataSource extends DataSource<any> 
{
  constructor(private _database: NoteDatabase) 
  {
    super();
  }

  connect(): Observable<Note[]> 
  {
    return this._database.dataChange;
  }

  disconnect() {}
}

