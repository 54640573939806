
/* 
['Image', 'ID', 'Dates', 'Stats', 'Actions'];
*/
.mat-mdc-cell
{    
    overflow: hidden;
}
.mat-column-Image 
{ 
    width: 64px;
}
.mat-column-ID 
{ 
    max-width: none;
}
.mat-column-Dates 
{ 
    white-space: nowrap;
}
.mat-column-Stats 
{ 
    max-width: none;
    white-space: nowrap;
}
.mat-column-Actions 
{ 
    width: 100px;
    max-width: 150px;
    font-size: 80%;
    padding-right: 0px;
}

.date-label
{
    display: inline-block;
    width: 70px;
}

.date
{
    white-space: nowrap;
}
