{
  "name": "market-admin",
  "version": "19.0.0",
  "license": "MIT",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "test": "ng test",
    "lint": "ng lint",
    "e2e": "ng e2e"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^19.1.4",
    "@angular/cdk": "^19.1.2",
    "@angular/common": "^19.1.4",
    "@angular/core": "^19.1.4",
    "@angular/forms": "^19.1.4",
    "@angular/material": "^19.1.2",
    "@angular/platform-browser": "^19.1.4",
    "@angular/platform-browser-dynamic": "^19.1.4",
    "@angular/router": "^19.1.4",
    "@swimlane/ngx-charts": "^20.5.0",
    "ngx-cookie": "^6.0.1"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^19.1.5",
    "@angular/cli": "^19.1.5",
    "@angular/compiler": "^19.1.4",
    "@angular/compiler-cli": "^19.1.4",
    "@angular/language-service": "^19.1.4",
    "@types/node": "^20.5.7",
    "core-js": "^3.32.1",
    "rxjs": "^7.0.0",
    "tslib": "^2.6.2",
    "typescript": "~5.7.3",
    "zone.js": "^0.15.0"
  }
}