<mat-toolbar>
    <span>{{title}}</span>
    <span class="app-toolbar-filler"></span>
    <button mat-button (click)="sendNotification()"><i class="material-icons">send</i> Send </button>
</mat-toolbar>

<div class="database-container">
    <table mat-table #table [dataSource]="dataSource">
  
      <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->
  
      <!-- Image Column -->
      <ng-container matColumnDef="Image">
        <th mat-header-cell *matHeaderCellDef>Image</th>
        <td mat-cell *matCellDef="let row"> <img [src]="row.ImageUrl" width=64 height=64 *ngIf="row.ImageUrl"></td>
      </ng-container>
  
      <!-- Sent Column -->
      <ng-container matColumnDef="Sent">
        <th mat-header-cell *matHeaderCellDef>Sent</th>
        <td mat-cell *matCellDef="let row"> {{row.Sent | date}} </td>
      </ng-container>
  
      <!-- Message Column -->
      <ng-container matColumnDef="Note">
        <th mat-header-cell *matHeaderCellDef>Notification</th>
        <td mat-cell *matCellDef="let row"> 
            <strong>{{row.Message}}</strong><br>
            <span class="text-muted">
            Url: 
            <a *ngIf="row.Url" href="{{getMarketWebsiteLink(row)}}" target="market">{{row.Url}}</a>
            <br>
            Endpoint: {{row.Endpoint}}<br>
            </span>
            <span class="text-muted" *ngIf="row.User">
              User: {{row.User}}
            </span>
          </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-progress-bar mode="indeterminate" *ngIf="working"></mat-progress-bar>
  </div>
  