<mat-toolbar>
    <span>{{title}}</span>
</mat-toolbar>

<mat-progress-bar mode="indeterminate" *ngIf="working"></mat-progress-bar>

  <table mat-table #table [dataSource]="dataSource">

    <ng-container matColumnDef="Sound">
      <th mat-header-cell *matHeaderCellDef>Sound</th>
      <td mat-cell *matCellDef="let row">
        <a href="{{this.config.host()}}/sound/show?id={{row.SoundUid}}" target="market"><img [src]="row.SoundImageUrl" width=64 height=64 *ngIf="row.SoundImageUrl"><br>{{row.SoundLabel}}</a>
      </td>
    </ng-container>

    <ng-container matColumnDef="User">
        <th mat-header-cell *matHeaderCellDef>User</th>
        <td mat-cell *matCellDef="let row">
          <div class="center"><a href="{{this.config.host()}}/user/{{row.UserUid}}" target="market"><img class="avatar64" [src]="row.AvatarUrl" width=64 height=64 *ngIf="row.AvatarUrl"><br>{{row.DisplayName}}</a></div>
        </td>
    </ng-container>

    <ng-container matColumnDef="Message">
      <th mat-header-cell *matHeaderCellDef>Message</th>
      <td mat-cell *matCellDef="let row">
        <strong>{{row.Message}}</strong>
        <br><br>
        <div class="nowrap">
          <i style="vertical-align: middle" class="material-icons heart-noselect" *ngIf="!row.UserHeart" (click)="toggleHeart(row)">favorite_border</i>
          <i style="vertical-align: middle" class="material-icons heart-select" *ngIf="row.UserHeart" (click)="toggleHeart(row)">favorite</i>
          <span *ngIf="row.Hearts > 0" class="arrow_box"> {{row.Hearts}}</span>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="Sent">
      <th mat-header-cell *matHeaderCellDef>Sent</th>
      <td mat-cell *matCellDef="let row"> {{row.RelTime}} <br> <span class="text-muted">{{row.PostedAt | date}}</span><br></td>
    </ng-container>

    <ng-container matColumnDef="Actions">
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let row">
            <button mat-button (click)="deleteComment(row)"><i class="material-icons action-delete">delete</i> Delete</button><br>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  

