<h2 mat-dialog-title>
    Add File
</h2>
<mat-dialog-content>
    <div>Add a WNA file to White Noise Market</div>
    <br/>
    <input type="file" (change)="fileChange($event)" placeholder="Upload WNA file">
    <br/><br/>
    <mat-progress-bar mode="indeterminate" *ngIf="working"></mat-progress-bar>
    <div>
        <div *ngIf="errorMsg.length > 0" style="color: red">{{errorMsg}}</div>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="onSubmit()">Add</button>
    <button mat-raised-button [mat-dialog-close]="false" color="warn">Cancel</button>
</mat-dialog-actions>
