<mat-toolbar class="app-toolbar" color="primary">
  <span><a routerLink="/" style="color: white;">Admin</a></span>

  <span *ngIf="soundService.authenticated && soundService.isAdmin">
  
    <button mat-button routerLink="/sounds" routerLinkActive="active">Sounds</button>
    <button mat-button routerLink="/comments" routerLinkActive="active">Comments</button>
    <button mat-button routerLink="/users" routerLinkActive="active">Users</button>
    <button mat-button routerLink="/tags" routerLinkActive="active">Tags</button>
    <button mat-button routerLink="/notifications" routerLinkActive="active">Notifications</button>
    <button mat-button routerLink="/settings" routerLinkActive="active">Settings</button>
    <button mat-button routerLink="/subscriptions" routerLinkActive="active">Subscriptions</button>
    <button mat-button routerLink="/store" routerLinkActive="active">MP3 Store</button>
    <button mat-button routerLink="/log" routerLinkActive="active">Logs</button>
    <button mat-button routerLink="/advanced" routerLinkActive="active">Advanced</button>
  </span>
    <!-- This fills the remaining space of the current row -->
    <span class="app-toolbar-filler"></span>

    <!-- login / logoff -->
    <span *ngIf="soundService.authenticated && soundService.isAdmin">
      <button mat-button (click)="open()">
        <img class="avatar" src="{{soundService.avatarUrl}}" width="24" height="24" alt="{{soundService.displayName}}" (error)="avatarError($event.target);">
        <span style="vertical-align: middle;"> {{ soundService.displayName }}</span>
      </button>
      <button mat-button (click)="logout()">
        Logout
      </button>
    </span>
  
</mat-toolbar>
<div class="content">
  <router-outlet></router-outlet>
</div>
