import { Component, OnInit } from '@angular/core';
import { TMApiService } from '../tmapi.service';
import { MatDialog } from '@angular/material/dialog';

import { StoreDetailsDialogComponent } from '../store-details-dialog/store-details-dialog.component';
import { PromoDialogComponent } from '../promo-dialog/promo-dialog.component';
import { AppConfig } from 'app/AppConfig';
import { PricingDialogComponent } from '../pricing-dialog/pricing-dialog.component';

@Component({
    selector: 'app-store',
    templateUrl: './store.component.html',
    styleUrls: ['./store.component.css'],
    standalone: false
})
export class StoreComponent implements OnInit {

  working = false;
  paging = false;
  offset = 0;
  limit = 20;
  count = 0;
  search = "";
  lastSearch = "";
  selectedTab = 0;
  data: any[];

  constructor(
    public tmapiService: TMApiService,
    private dialog: MatDialog,
    public config: AppConfig) { }

  ngOnInit()
  {
    this.working = true;
    this.tmapiService.init().then(() =>
    {
      this.getData();
    });
  }

  getData()
  {
    let name = this.getDataType();
    if (name.length == 0)
    {
      console.error("Invalid data name.");
      return;
    }

    this.working = true;
    this.tmapiService.getAdmin(name, this.offset, this.limit)
                    .then(data => this.setData(data))
                    .catch(error => this.setError(error));
  }

  getBestOrderDate(item: any) {
    let date = item.order_updated;
    if (date == null || date == undefined || date.length == 0) {
      date = item.order_created;
    }
    return date;
  }

  getSearchData()
  {
    if (this.working)
    {
      console.error("Already getting log!");
      return;
    }
    if(!this.search)
    {
      console.error("No search term entered.");
      return;
    }

    let name = this.getDataType();
    if (name.length == 0)
    {
      console.error("Invalid data name.");
      return;
    }

    console.log("Search "+name+" for query: " + this.search);
    this.working = true;
    this.count = 0;
    this.lastSearch = "";

    // perform search and call setSounds with results
    this.tmapiService.getAdminSearch(name, this.search, this.offset, this.limit)
                    .then(data => {
                      this.lastSearch = this.search;
                      return this.setData(data);
                    })
                    .catch(err => this.setError(err));
  }

  onSearch()
  {
    if (this.working)
    {
      console.error("Already getting orders!");
      return;
    }
    if (!this.search || this.search.length == 0)
    {
      // just get users by default if no search provided
      this.lastSearch = "";
      this.offset = 0;
      this.getData();
    }
    else
    {
      // reset offset to start
      this.offset = 0;
      this.getSearchData();
    }
  }

  onDetails(data: any)
  {
    this.dialog.open(StoreDetailsDialogComponent, {data: data})
               .afterClosed()
               .subscribe(result => {
                 // todo if we want to do anything after
               });
  }

  onAddPromo()
  {
    this.dialog.open(PromoDialogComponent)
               .afterClosed()
               .subscribe(result => {
                 if (result)
                 {
                  this.setData(result);
                 }
               });
  }

  onAddPrice()
  {
    this.dialog.open(PricingDialogComponent)
               .afterClosed()
               .subscribe(result => {
                 if (result)
                 {
                  this.setData(result);
                 }
               });
  }

  onSoundSync()
  {
    // sync and refresh
    this.working = true;
    this.tmapiService.syncSounds()
        .then(data => this.setData(data))
        .catch(err => this.setError(err));
  }

  onEdit(data: any)
  {
    if (this.selectedTab == 1) {
      this.dialog.open(PricingDialogComponent, {data: data})
      .afterClosed()
      .subscribe(result => {
        if (result)
        {
         this.setData(result);
        }
      });
    } 
    else if (this.selectedTab == 5) {
      this.dialog.open(PromoDialogComponent, {data: data})
      .afterClosed()
      .subscribe(result => {
        if (result)
        {
         this.setData(result);
        }
      });
    }
  }

  setData(data: any)
  {
    this.working = false;
    this.paging = false;
    this.data = data;
    if (this.data)
    {
      this.count = this.data.length;
    }
    else
    {
      this.count = 0;
    }
    console.log("Received data: " + JSON.stringify(this.data));
  }

  setError(error: any)
  {
    console.error("ERROR: Problem fetching data: " + error);
    this.working=false;
    this.paging = false;
  }

  page(forward: boolean): void
    {
      this.offset += (forward ? 1 : -1) * this.limit;
      if (this.offset < 0) this.offset = 0;

      this.paging = true;
      if (!this.search || this.search.length == 0)
      {
        this.getData();
      }
      else
      {
        this.getSearchData();
      }
    }

  setTab(index: number)
  {
    //console.log("setTab: " + index);
    this.selectedTab = index;
    this.offset = 0;
    this.search = "";
    this.lastSearch = "";
    this.getData();
  }

  getDataType(): string
  {
    if (this.selectedTab == 0)
    {
      return "sounds";
    }
    else if (this.selectedTab == 1)
    {
      return "pricing";
    }
    else if (this.selectedTab == 2)
    {
      return "orders";
    }
    else if (this.selectedTab == 3)
    {
      return "log";
    }
    else if (this.selectedTab == 4)
    {
      return "urls";
    }
    else if (this.selectedTab == 5)
    {
      return "promo";
    }
    else
    {
      return "";
    }
  }

  getColumnIds(): string[]
  {
    // sounds
    if (this.selectedTab == 0)
    {
      return ['sound_id', 'sound_label', 'sound_slug', 'sound_status', 'details'];
    }
    // pricing
    else if (this.selectedTab == 1)
    {
      return ['pricing_duration', 'pricing_price', 'edit'];
    }
    // orders
    else if (this.selectedTab == 2)
    {
      return ['order_id', 'order_date', 'order_name', 'order_email', 'order_user', 'order_total', 'details'];
    }
    // downloads
    else if (this.selectedTab == 3)
    {
      return ['log_id', 'log_url', 'log_user', 'log_date', 'details'];
    }
    // urls
    else if (this.selectedTab == 4)
    {
      return  ['url_order_id', 'url_created', 'url_id', 'url_url', 'download', 'details'];
    }
    // promo
    else if (this.selectedTab == 5)
    {
      return ['promo_id', 'discount', 'enabled', 'edit'];
    }

    return [];
  }
}
