import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params }   from '@angular/router';
import { Location }                 from '@angular/common';

import { Sound } from './sound';
import { SoundService } from './sound.service';

@Component({
    selector: 'market-token',
    templateUrl: './token.component.html',
    standalone: false
})

export class TokenComponent implements OnInit
{
    title: string;
    token: string;

    constructor(private soundService: SoundService,
                private route: ActivatedRoute,
                private location: Location)
    {
    }

    ngOnInit(): void
    {
        // if browser is going directly to search location then set the search bar text to value
        this.token = location.pathname.substring(7);
        
         // single route param subscribe:
         console.log("Initializing token: " + this.token);
         this.setToken(this.token);
         //this.route.params.switchMap((params: Params) => { this.setToken(params['token']); return null; });
    }

    setToken(token : string)
    {
        console.log("Setting token: " + token);
        this.title = "Set token: " + token;
        return this.soundService.setToken(token);
    }

    handleError(error : any) : void
    {
        // on error set an empty array and working to false
        this.title = "Error!";
    }

}
