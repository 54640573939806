<h2 mat-dialog-title>
    {{ title }}
</h2>
<mat-dialog-content *ngIf="this.type==0">
    <div fxLayout="column">
        <mat-form-field>
            <mat-label>Name</mat-label>
            <input matInput value="" [(ngModel)]="setting.Name" (input)="clearError()" required [disabled]="edit">
        </mat-form-field>
        <mat-form-field>
            <textarea matInput rows="8" placeholder="Value:" value="" [(ngModel)]="setting.Value" (input)="clearError()"></textarea>
        </mat-form-field>
        <div *ngIf="badInput" style="color:red; font-weight:600">
            Please fill out all fields.
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-content *ngIf="this.type==1">
    <div fxLayout="column">
        <mat-form-field>
            <mat-label>Sort</mat-label>
            <input matInput value="" type="number" [(ngModel)]="setting.Sort">
        </mat-form-field>
        <mat-form-field>
            <mat-label>Section</mat-label>
            <input matInput value="" [(ngModel)]="setting.Section">
        </mat-form-field>
        <mat-form-field>
            <mat-label>Name</mat-label>
            <input matInput value="" [(ngModel)]="setting.Name" (input)="clearError()" required [disabled]="edit">
        </mat-form-field>
        <mat-form-field>
            <mat-label>Type</mat-label>
            <mat-select value="" [(ngModel)]="setting.Type">
                <mat-option *ngFor="let t of settingTypes" [value]="t.value">{{t.label}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Title</mat-label>
            <input matInput value="" [(ngModel)]="setting.Title" (input)="clearError()" required>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Description</mat-label>
            <textarea matInput rows="4" value="" [(ngModel)]="setting.Description"></textarea>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Default Value</mat-label>
            <input matInput value="" [(ngModel)]="setting.DefValue">
        </mat-form-field>
        <mat-form-field>
            <mat-label>Options</mat-label>
            <input matInput value="" [(ngModel)]="setting.Options">
        </mat-form-field>
        <mat-form-field>
            <mat-label>Min</mat-label>
            <input matInput value="" type="number" [(ngModel)]="setting.Min">
        </mat-form-field>
        <mat-form-field>
            <mat-label>Max</mat-label>
            <input matInput value="" type="number" [(ngModel)]="setting.Max">
        </mat-form-field>
        <mat-form-field>
            <mat-label>Step</mat-label>
            <input matInput value="" type="number" [(ngModel)]="setting.Step">
        </mat-form-field>
        <mat-form-field>
            <mat-label>Zen</mat-label>
            <input matInput value="" type="number" [(ngModel)]="setting.Zen">
        </mat-form-field>
        <mat-form-field>
            <mat-label>Regex</mat-label>
            <input matInput value="" [(ngModel)]="setting.Regex">
        </mat-form-field>
        <div *ngIf="badInput" style="color:red; font-weight:600">
            Please fill out all required fields.
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="onSubmit()" *ngIf="edit == false">Add Setting</button>
    <button mat-raised-button color="primary" (click)="onSubmit()" *ngIf="edit == true">Edit Setting</button>
    <button mat-raised-button color="warn" (click)="onRemove()" *ngIf="edit == true">Delete</button>
    <button mat-raised-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>