import { Component, Inject, ViewEncapsulation } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

import { SoundService } from "./sound.service";
import { Sound } from "./sound";

@Component({
    templateUrl: "./sound-attach-dialog.component.html",
    standalone: false
})
export class SoundAttachDialogComponent
{
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private soundService: SoundService,
        private dialogRef: MatDialogRef<SoundAttachDialogComponent>
    ){
        this.sound = data as Sound;
     }

    public sound: Sound;
    public errorMsg = "";
    private file: File = null;
    
    fileChange(event) 
    {
        var fileList: FileList = event.target.files;
        if (fileList.length > 0) 
        {
            console.log("selected file " + fileList[0].name);
            this.file = fileList[0];
            this.errorMsg = "";
        }
    }
    
    onSubmit(): void
    {        
        console.log("sending file " + (this.file == null ? "null" : this.file.name));
        if(!this.file)
        {
            this.errorMsg = "Please select a file to attach.";
            return;
        }
        this.soundService.attachAdminFile(this.sound, this.file)
            .then(result => { 
                console.log(result); 
                this.dialogRef.close(result); 
            })
            .catch(err => { 
                console.log("ERROR: " + JSON.stringify(err));
                if(err.Message) this.errorMsg = err.Message;
                else this.errorMsg = "An error occurred uploading file, please try again.";
                return Promise.reject(err.Message || err); 
            });
    }
}