import { Component, ViewContainerRef, OnInit } from '@angular/core';
import { Router, ActivatedRoute }   from '@angular/router';
import { SoundService } from './sound.service';
import { environment } from '../environments/environment';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    standalone: false
})

export class AppComponent implements OnInit
{
  title = 'Market Admin';

  constructor(private route: ActivatedRoute,
              private vcr: ViewContainerRef,
              public soundService: SoundService,
              private router: Router) 
  {
  }

  ngOnInit(): void
  {
  }

  open(): void
  {
    // open admin profile in new window
    var url = environment.host + "/user/" + this.soundService.userId;
    window.open(url, '_blank');
  }

  logout(): void
  {
    this.soundService.logoff();
    this.router.navigateByUrl("/");
  }
}
