/*  ['Sound', 'User', 'Message', 'Sent', 'Actions']; */

.heart-select { color: red; }

.mat-column-Sound 
{ 
    word-wrap: break-word;    
    width: 200px;
    max-width: 350px;
    padding: 0 8px 0 0;
}
.mat-column-User 
{ 
    word-wrap: break-all;
    max-width: 120px;
    width: 120px;
}
.mat-column-Message
{    
    max-width: none;
    word-wrap: break-word;
}
.mat-column-Sent 
{
    width: 120px;
    max-width: 120px;
    white-space: nowrap;    
}
.mat-column-Actions 
{ 
    white-space: nowrap;
    width: 100px;
    max-width: 100px;
}