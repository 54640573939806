import { Component, OnInit } from '@angular/core';
import { Router }   from '@angular/router';

import { SoundService } from './sound.service';
import { AppConfig } from './AppConfig';
import { table } from 'console';

@Component({
    selector: 'market-home',
    templateUrl: './home.component.html',
    standalone: false
})

export class HomeComponent implements OnInit
{
    title = "Home";    
    
    constructor(
      private soundService: SoundService,
      private router: Router,
      public  config: AppConfig) { }

    retryCount = 0;
    showLogin = true;
    showDenied = false;
    showDashboard = false;
    working = true;

    // database queries
    queries = [ { name: 'Overall', label: 'Overall', procedure: 'report_overall', params: ""},
                { name: 'Users', label: 'Users', procedure: 'report_user', params: ""},
                { name: 'Downloads', label: 'Downloads', procedure: 'report_downloads', params: ""},
                { name: 'Uploads', label: 'Uploads', procedure: 'report_uploads', params: ""},
                { name: 'Comments', label: 'Comments', procedure: 'report_comment', params: ""},
                { name: 'Hearts', label: 'Hearts', procedure: 'report_flag', params: "1"},
                { name: 'Hearts', label: 'Tags', procedure: 'report_flag', params: "2"},
                { name: 'Hearts', label: 'Users', procedure: 'report_flag', params: "3"},
                { name: 'Subscriptions', label: 'Apple', procedure: 'report_subscriptions', params: "apple"},
                { name: 'Subscriptions', label: 'Google', procedure: 'report_subscriptions', params: "google"},
                { name: 'Logs', label: 'Warning', procedure: 'report_log', params: "W"},
                { name: 'Logs', label: 'Error', procedure: 'report_log', params: "E"}
    ]
    query = 0;

    // chart results
    overall = [];
    users = [];
    downloads = [];
    uploads = [];
    comments = [];
    hearts = [];
    subscriptions = [];
    logs = [];
    
    // options for the chart
    showXAxis = true;
    showYAxis = true;
    gradient = true;
    showLegend = false;
    showXAxisLabel = false;
    xAxisLabel = 'Time';
    showYAxisLabel = false;
    yAxisLabel = 'Total';
    timeline = false;
  
    colorScheme = {
      domain: ['#9370DB', '#87CEFA', '#FA8072', '#FF7F50', '#90EE90', '#7E87FF']
    };
    storeScheme = {
      domain: ['#7280FA', '#90EE90']
    };
    logScheme = {
      domain: ['#FFFD01', '#FA4032']
    };
    cardColor: string = '#232837';
  
    // pie
    showLabels = true;

    // chart data to plot
    series = [];
   
    ngOnInit(): void
    {      
      //console.log("Results:", this.results);
      this.checkLoginStatus(100);
    }

    // check log in status after waiting for t milliseconds
    checkLoginStatus(t: number): void
    {
      setTimeout(() => {
        if (this.soundService.authenticated && this.soundService.isAdmin)
        {
          console.log("Admin has logged in.  Showing dashboard.");          
          this.working = false;
          this.showLogin = false;
          this.showDenied = false;
          this.showDashboard = true;
          
          // perform first query
          this.checkQuery();
        }
        else 
        {
          this.retryCount += 1;
          if (this.retryCount > 5)
          {            
            console.error("Access Denied");
            this.working = false;
            this.showLogin = false;
            this.showDenied = true;
            this.showDashboard = false;
          }
          else
          {
            console.info("Retrying...");
            this.checkLoginStatus(200);
            this.working = true;
            this.showLogin = true;
            this.showDenied = false;
            this.showDashboard = false;
          }
        }
      }, t);
    }

    checkQuery(): void
    {
      // check if more queries need to be performed
      if (this.query < this.queries.length)
      {
        this.working = true;
        this.getData(this.queries[this.query]);
        this.query += 1;
      }
      else 
      {        
        // no more queries to perform
        this.working = false;
      }
    }

    getData(query: any)
    {      
      let name = query["name"]
      let label = query["label"]
      let procedure = query["procedure"]
      let params = query["params"]            

      this.soundService.getAdminCall(procedure, params)
        .then(data => this.setData(name, label, data))
        .catch(err => this.setError(name, err));      
      
    }

    setData(name: string, label: string, data: any)
    { 
      console.log("Received data for", name, "label:", label, "data:", data)            
      let result = { name: label, series: data };
      switch (name) {
        case "Overall":
          // convert strings to integer
          for (let i = 0; i < data.length; i++)
          {
            data[i].value = parseInt(data[i].value, 10);
          }
          this.overall = data          
          break;
        case "Users":
          this.users.push(result);
          this.users = [...this.users];
          break;
        case "Downloads":
          this.downloads.push(result);
          this.downloads = [...this.downloads];
          break;
        case "Uploads":
          this.uploads.push(result);
          this.uploads = [...this.uploads];
          break;
        case "Comments":
          this.comments.push(result);
          this.comments = [...this.comments];
          break;
        case "Hearts":
          this.hearts.push(result);
          this.hearts = [...this.hearts];
          break;
        case "Subscriptions":
          this.subscriptions.push(result);
          this.subscriptions = [...this.subscriptions];
          break;
        case "Logs":
          this.logs.push(result);
          this.logs = [...this.logs];
          break;
      }

      // check for additional queries  
      this.checkQuery();
    }

    setError(name: string, err: any)
    {
      console.error("Error during request for", name, "error: ", err);
      this.working = false;
    }

}
