<h2 mat-dialog-title>{{this.title}}</h2>
<mat-dialog-content>
    <div class="dialog-column">
        <div>
            <div *ngIf="error" style="color:red; font-weight:600">
                {{this.error}}
            </div>
            <mat-form-field fxFlex>
                <span class="muted">{{this.duration / 3600}} Hrs</span>
                <input matInput type="number" step="3600" min="0" placeholder="Duration:" value="" [(ngModel)]="this.duration" required [disabled]="edit==true">
            </mat-form-field>
        </div>
        <mat-form-field fxFlex>
            <input matInput type="number" step="0.01" placeholder="Price:" value="" [(ngModel)]="this.price" required>
        </mat-form-field>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="onSubmit()" *ngIf="edit==false">Add</button>
    <button mat-raised-button color="primary" (click)="onSubmit()" *ngIf="edit==true">Update</button>
    <button mat-raised-button color="warn" (click)="onRemove()" *ngIf="edit==true">Delete</button>
    <button mat-raised-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>