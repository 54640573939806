<h2 mat-dialog-title>{{this.title}}</h2>
<mat-dialog-content>
    <div class="dialog-column">
        <div>
            <div *ngIf="error" style="color:red; font-weight:600">
                {{this.error}}
            </div>
            <mat-form-field fxFlex>
                <input matInput placeholder="Name:" value="" [(ngModel)]="this.name" (input)="onPromoInput()" required [disabled]="edit">
            </mat-form-field>
        </div>
        <mat-form-field fxFlex>
            <input matInput placeholder="Value:" value="" [(ngModel)]="this.value" (input)="onValueInput()" required>
        </mat-form-field>

        <mat-checkbox color="primary" [(ngModel)]="enabled">Enabled</mat-checkbox>
    </div>

</mat-dialog-content>
<mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="onSubmit()" *ngIf="edit==false">Add</button>
    <button mat-raised-button color="primary" (click)="onSubmit()" *ngIf="edit==true">Update</button>
    <button mat-raised-button color="warn" (click)="onRemove()" *ngIf="edit==true">Delete</button>
    <button mat-raised-button mat-dialog-close >Cancel</button>
</mat-dialog-actions>