/*
 ['Image', 'TagLabel', 'TagValue', 'Sort', 'Description', 'Actions'];
 */
 .mat-column-Image 
{ 
    width: 64px;
    max-width: 72px;
    padding: 0 0 0 8px;
}
.mat-column-TagLabel
{
    max-width: 200px;
}
.mat-column-TagValue
{
    max-width: 150px;
}
.mat-column-Sort
{
    max-width: 80px;
}
.mat-column-Actions 
{ 
    width: 100px;
    max-width: 150px;    
    padding: 8px;
}
.mat-column-Description
{
    max-width: none;
}