import { Component, OnInit } from '@angular/core';
import { ActivatedRoute }   from '@angular/router';
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";

import { SoundService } from '../sound.service';

// cdk table
import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';

// data
import { Setting } from './setting';
import { SettingDialogComponent } from './setting-dialog.component';

@Component({
  selector: 'market-settings',
  templateUrl: './settings.component.html',
})

export class SettingsComponent implements OnInit
{
    title = "Settings";
    working = false;
    selectedTab = 0;

    // data table
    displayedColumns = ['Name', 'Value', 'Actions'];
    database = new SettingsDatabase();
    dataSource: SettingsDataSource | null;
  
    constructor(private soundService: SoundService,
                private route: ActivatedRoute,
                private dialog: MatDialog)
    {}

    ngOnInit(): void
    {
      this.working = true;
      this.dataSource = new SettingsDataSource(this.database);
      this.refreshSettings();
    }

    setTab(index: number)
    {
      //console.log("setTab: " + index);
      this.selectedTab = index;
      if (this.selectedTab == 0) {
        this.displayedColumns = ['Name', 'Value', 'Actions'];
      } else {
        this.displayedColumns = [
          'Sort',
          'Section',
          'Name', 
          'Type', 
          'Title', 
          'Description', 
          'Default', 
          'Options', 
          'Min', 
          'Max', 
          'Step', 
          'Zen',  
          'Regex', 
          'Actions'];
      }
      this.refreshSettings();
    }

    refreshSettings()
    {
      console.log("refreshing settings");
      if (this.selectedTab == 0) {
        this.soundService.getAdminSettings()
        .then(settings => this.setSettings(settings))
        .catch(err => this.setError(err));   
      } else {
        this.soundService.getAdminUserSettings()
        .then(settings => this.setSettings(settings))
        .catch(err => this.setError(err));
      }   
    }
  
    setSettings(settings: Setting[])
    {
      this.working = false;
      this.database.dataChange.next(settings);
    }
  
    setError(err: any)
    {
      console.info("Error during query!");
      this.working = false;
    }

    addSetting(): void
    {
      console.log("clicked add setting");
      let opts = {
        data: {
          type: this.selectedTab
        }
      }
      this.dialog.open(SettingDialogComponent, opts).afterClosed()
        .subscribe(result => { 
          if(!result)
          {
            console.log("no setting added - user canceled operation");
          }
          else
          {
            console.log("updating with new settings " + result);
            this.refreshSettings();
          }
        });
    }

    editSetting(row: Setting): void
    {
      console.log("editing setting " + JSON.stringify(row));
      let options = { 
        data: {
          edit: true,
          type: this.selectedTab,
          setting: row
        }
      };
      this.dialog.open(SettingDialogComponent, options).afterClosed()
        .subscribe(result => {

          if(!result)
          {
            console.log("no setting edited - user canceled operation");
          }
          else
          {
            console.log("edited setting " + result);
            this.refreshSettings();
          }
        });
    }
}

export class SettingsDatabase 
{
  dataChange: BehaviorSubject<Setting[]> = new BehaviorSubject<Setting[]>([]);
  get data(): Setting[] { return this.dataChange.value; }
  
  constructor() {}
}

export class SettingsDataSource extends DataSource<any> 
{
  constructor(private _database: SettingsDatabase) 
  {
    super();
  }

  connect(): Observable<Setting[]> 
  {
    return this._database.dataChange;
  }

  disconnect() {}
}

