import { Component, OnInit } from '@angular/core';
import { SoundService } from '../sound.service';
import { CookieService } from 'ngx-cookie';

@Component({
    selector: 'market-logs',
    styleUrls: ['./logs.component.css'],
    templateUrl: './logs.component.html',
    standalone: false
})

export class LogComponent implements OnInit
{
    title = "Logs";
    working = false;
    message = "";
    paging = false;
    offset = 0;
    limit = 100;
    count = 0;

    warnings = false;
    filterAdd = "";
    filters = [];

    // data table
    displayedColumns = ['action', 'date', 'severity', 'module', 'message'];
    data: any;
    
    constructor(
      private soundService: SoundService,
      private cookies: CookieService) { 
    }

    ngOnInit(): void {

      // load saved filters from cookies
      let saved = this.cookies.get("log-filters");
      if (saved && saved.length > 0) {
        this.filters = JSON.parse(saved);
        if (!this.filters) this.filters = [];
      }

      let warnings = this.cookies.get("log-warnings");
      if (warnings && warnings.length > 0) {
        this.warnings = (typeof warnings === 'string') ? (warnings.toLowerCase() === 'true') : Boolean(warnings);
      }

      this.getData();
    }

    private getData() {
      this.working = true;
      this.soundService.getAdminLogs(this.offset, this.limit, this.filters, this.warnings)
      .then(data => {
        this.setData(data);
        this.working = false;
        this.paging = false;
      })
      .catch(err => {
        this.setError(err)
        this.working = false;
        this.paging = false;
      });
    }

    page(forward: boolean): void {
      this.offset += (forward ? 1 : -1) * this.limit;
      if (this.offset < 0) this.offset = 0;

      this.paging = true;
      this.getData();
    }

    addFilter() {
      this.addFilterString(this.filterAdd);
      this.filterAdd = "";
    }

    addFilterString(s: string) {
      if (s && s.length > 0) {
        s = s.trim();
        if (this.filters.includes(s) == false) {
          this.filters.push(s);
          this.saveFilters();
          this.getData();
        }
      }
    }

    onWarningsChange(b: boolean) {
      console.log("Warnings changed: ", b);
      this.warnings = b;
      this.saveFilters();
      this.getData();
    }

    removeFilter(s: string) {
      if (s && s.length > 0) {
        s = s.trim();
        this.filters = this.filters.filter(f => f != s);
        this.saveFilters();
        this.getData();
      }
    }

    saveFilters() {
      let save = JSON.stringify(this.filters);
      this.cookies.put("log-filters", save, { path: "/", domain: location.hostname });

      let warnings = String(this.warnings);
      this.cookies.put("log-warnings", warnings, { path: "/", domain: location.hostname });
    }

    setData(data: any) {
      this.message = "";  
      this.data = data;
    
      if (!data || data.length == 0) {
        this.message = "No logs found";
        this.count = 0;
      } else {
        this.count = data.length;
      }
    }
  
    setError(err: any) {
      console.error("Error during query: ", err);
      this.message = err['Message'];
    }

    isErrorRow(row: any) {
      return row.Severity && row.Severity == "E";
    }
}
