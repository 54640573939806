<h2 mat-dialog-title>
    <div>Edit User</div>
    <div style="font-size: 10pt">{{user.Email}} ({{user.Uuid}})</div>
    <div *ngIf="displayMsg.length > 0" class="message" [innerHtml]="displayMsg"></div>
</h2>
<mat-dialog-content>
    <div class="dialog-column">
        <div>
            <mat-form-field>
                <mat-label>First Name</mat-label>
                <input matInput value="" [(ngModel)]="this.userData.NameFirst">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Last Name</mat-label>
                <input matInput value="" [(ngModel)]="this.userData.NameLast">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Nickname</mat-label>
                <input matInput value="" [(ngModel)]="this.userData.Nickname">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Badges</mat-label>
                <input matInput value="" [(ngModel)]="this.userData.Badges">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Username</mat-label>
                <input matInput value="" [(ngModel)]="this.userData.UserName">
            </mat-form-field>
            <mat-form-field>
                <mat-label>User Website</mat-label>
                <input matInput value="" [(ngModel)]="this.userData.UserWebsite">
            </mat-form-field>
            <mat-form-field>
                <mat-label>User Description</mat-label>
                <input matInput value="" [(ngModel)]="this.userData.UserDescription">
            </mat-form-field>
        </div>
        <hr/>
        <div class="container">
            <mat-form-field>
                <mat-label>Roles</mat-label>
                <input readonly matInput placeholder="Roles:" value="" [(ngModel)]="user.Role">
            </mat-form-field>
            <table mat-table #table [dataSource]="this.data">
                <!-- Add Column -->
                <ng-container matColumnDef="add">
                  <th mat-header-cell *matHeaderCellDef>
                    <span style="font-size: 80%">Add</span>
                    <br/>
                    <mat-icon style="color: #AAA">add</mat-icon>
                  </th>
                  <td mat-cell *matCellDef="let row">
                    <span class="radio-cell"><input type="radio" [(ngModel)]="roleValue" name="role" [value]="row.add"></span>
                  </td>
                </ng-container>

                <!-- Remove Column -->
                <ng-container matColumnDef="remove">
                    <th mat-header-cell *matHeaderCellDef>
                        <span style="font-size: 80%">Rem</span>
                        <br/>
                        <mat-icon style="color: #AAA">remove</mat-icon>
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <span class="radio-cell"><input type="radio" [(ngModel)]="roleValue" name="role" [value]="row.del"></span>
                    </td>
                </ng-container>

                <!-- Label Column -->
                <ng-container matColumnDef="label">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let row">
                        <span class="radio-label">{{row.label}}</span>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table> 
        </div>
        <br/>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="onSubmit()"><span *ngIf="roleValue">Save</span><span *ngIf="!roleValue">Update</span></button>
    <button mat-raised-button [mat-dialog-close]="true" color="warn">Close</button>
</mat-dialog-actions>
