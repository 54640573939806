<mat-toolbar>
    <span>{{title}}</span>
    <span>&nbsp;({{offset}}-{{offset+count}})</span>
    <span class="app-toolbar-filler"></span>
</mat-toolbar>

<mat-progress-bar mode="indeterminate" *ngIf="working"></mat-progress-bar>

<div id="content">
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>Filters</mat-panel-title>
      <mat-panel-description>
        {{filters.length}} active filters
      </mat-panel-description>
    </mat-expansion-panel-header>
    <form class="filter-form" (ngSubmit)="addFilter()">
      <mat-checkbox color="primary" [(ngModel)]="warnings" name="warnings" (change)="onWarningsChange($event.checked)">Show Warnings</mat-checkbox>
      <mat-form-field>
        <input matInput name="filterAdd" [(ngModel)]="filterAdd" placeholder="Add keywords, message, or module" value="">
      </mat-form-field>
    </form>
    <mat-chip-listbox>
      <mat-chip-option *ngFor="let f of filters" selectable="false" removable="true" (removed)="removeFilter(f)">
        {{f}}<mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip-option>
    </mat-chip-listbox>
  </mat-expansion-panel>
  <div class="child-content">
    <div class="center-container" *ngIf="this.message">
      <p>{{this.message}}</p>
    </div>
    <div class="page-buttons" *ngIf="count > 0">
      <button mat-raised-button (click)="page(false)" [disabled]="offset == 0 && !paging">Prev</button>
      <button mat-raised-button (click)="page(true)" [disabled]="count < limit && !paging">Next</button>
    </div> 
    <table mat-table #table [dataSource]="this.data" *ngIf="!this.message">
      <!-- Action Column -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">
          <button class="filter-button" (click)="addFilterString(row.Module)"><mat-icon>filter_alt</mat-icon></button>
        </td>
      </ng-container>

      <!-- Date Column -->
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef>Date</th>
        <td mat-cell *matCellDef="let row">{{row.Date}}</td>
      </ng-container>
  
      <!-- Severity Column -->
      <ng-container matColumnDef="severity">
        <th mat-header-cell *matHeaderCellDef>Severity</th>
        <td mat-cell *matCellDef="let row">
          <div class="normal">
            <span>{{row.Severity}}</span>
          </div>
        </td>
      </ng-container>
  
      <!-- Module Column -->
      <ng-container matColumnDef="module">
        <th mat-header-cell *matHeaderCellDef>Module</th>
        <td mat-cell *matCellDef="let row">{{row.Module}}</td>
      </ng-container>
  
      <!-- Message Column -->
      <ng-container matColumnDef="message">
        <th mat-header-cell *matHeaderCellDef>Message</th>
        <td mat-cell *matCellDef="let row"> {{row.Message}} </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
  <div class="page-buttons" *ngIf="count > 0">
    <button mat-raised-button (click)="page(false)" [disabled]="offset == 0 && !paging">Prev</button>
    <button mat-raised-button (click)="page(true)" [disabled]="count < limit && !paging">Next</button>
  </div>  
</div>
  