<mat-toolbar>
    <span>
      {{title}}
      <span *ngIf="lastSearch.length">containing "{{lastSearch}}"</span>
      <span *ngIf="soundCount > 0">({{curOffset + 1}} - {{(curOffset + soundCount)}})</span>
      <span *ngIf="soundCount == 0">(0)</span>
    </span>

    <span class="app-toolbar-filler"></span>

    <div>
      <mat-form-field class="sort-form">
        <mat-select placeholder="Sort" [(ngModel)]="sort">
          <mat-option *ngFor="let opt of sortOptions" [value]="opt.value">{{opt.label}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div>
      <form class="app-toolbar-form toolbar-second-row">
          <input matInput placeholder="Search" class="app-toolbar-search" name="search" [(ngModel)]="search">
          <button mat-button (click)="searchSounds()" [disabled]="search.length == 0">
            <i class="material-icons">search</i>
          </button>
      </form>
    </div>
    <button mat-button (click)="addSound()"><i class="material-icons">add</i> Add </button>
  </mat-toolbar>

  <!-- [(selectedIndexChange)]="selectedIndex"  [(ngModel)]="tabs" -->
  <mat-tab-group (selectedTabChange)="setState($event.index)" id="tabs" name="tabs">
      <mat-tab>
        <!-- during search we set index to this tab, so to clear search (and see all sounds) we need a click event handler (which mat-tab doesn't support, hence the <a>) -->
        <ng-template mat-tab-label>
          <a class="all-tab-link" (click)="setState(0)">All</a>
        </ng-template>
      </mat-tab>
      <mat-tab label="Published"></mat-tab>
      <mat-tab label="Tagged"></mat-tab>
      <mat-tab label="Featuring"></mat-tab>
      <mat-tab label="Overflow"></mat-tab>
      <mat-tab label="Admin Upload"></mat-tab>
      <mat-tab label="Special"></mat-tab>
      <mat-tab label="High Priority"></mat-tab>
      <mat-tab label="Low Priority"></mat-tab>
      <mat-tab label="Edit Sound"></mat-tab>
      <mat-tab label="Edit Photo"></mat-tab>
      <mat-tab label="Disapproved"></mat-tab>
      <mat-tab label="Private"></mat-tab>
      <mat-tab label="Blocked"></mat-tab>
</mat-tab-group>

<div class="database-container">
  <table mat-table #table [dataSource]="dataSource">

    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" 
          ['Image', 'Sound', 'Dates', 'Stats', 'Tags', 'Description', 'Download', 'Actions'];
    -->

    <!-- Image Column -->
    <ng-container matColumnDef="Image">
      <th mat-header-cell *matHeaderCellDef>Image</th>
      <td mat-cell *matCellDef="let row"> <a href="{{this.config.host()}}/sound/{{row.Slug}}?id={{row.Uid}}" target="market"><img [src]="row.ImageUrl" width=64 height=64></a></td>
    </ng-container>

    <!-- Sound Column (Label, StateLabel, and Version) -->
    <ng-container matColumnDef="Sound">
      <th mat-header-cell *matHeaderCellDef>Sound</th>
      <td mat-cell *matCellDef="let row"> <strong>{{row.Label}}</strong> <span class="text-muted"><br> {{row.StateLabel}} <br> v{{row.Version}}</span></td>
    </ng-container>

    <!-- Dates Column -->
    <ng-container matColumnDef="Dates">
      <th mat-header-cell *matHeaderCellDef>Dates</th>
      <td mat-cell *matCellDef="let row"> <i class="material-icons text-muted text-fixed">upload</i> {{row.UploadDate | date}} <br><i class="material-icons text-muted text-fixed">mic</i> {{row.RecordDate | date}} </td>
    </ng-container>

    <!-- Stats Column -->
    <ng-container matColumnDef="Stats">
      <th mat-header-cell *matHeaderCellDef> Stats </th>
      <td mat-cell *matCellDef="let row">
          <i class="material-icons text-muted text-fixed" style="vertical-align: middle;">favorite</i> {{row.Stats.Hearts || 0 | number }} <br>
          <i class="material-icons text-muted text-fixed" style="vertical-align: middle;">comments</i> {{row.Stats.Comments || 0 | number}} <br>
          <i class="material-icons text-muted text-fixed" style="vertical-align: middle;">download</i> {{row.Stats.Downloads || 0 | number}} <br>
          <i class="material-icons text-muted text-fixed" style="vertical-align: middle;">headphones</i> {{row.Stats.Minutes || 0 | number}}
      </td>
    </ng-container>

    
    <!-- Tags Column -->
    <ng-container matColumnDef="Tags">
      <th mat-header-cell *matHeaderCellDef> Tags </th>
      <td mat-cell *matCellDef="let row"> {{row.Tags}} </td>
    </ng-container>

    <!-- Description Column -->
    <ng-container matColumnDef="Description">
      <th mat-header-cell *matHeaderCellDef> Description </th>
      <td mat-cell *matCellDef="let row"> {{row.Description}}<br>
        <span *ngIf="row.PreviewUrl"><a href="{{this.config.host()}}{{row.PreviewUrl}}" target="store">Preview</a>&nbsp;</span>
        <span *ngIf="row.AmazonUrl"><a href="{{row.AmazonUrl}}" target="store">Amazon</a>&nbsp;</span>
        <span *ngIf="row.ITunesUrl"><a href="{{row.ITunesUrl}}" target="store">iTunes</a>&nbsp;</span>
        <span *ngIf="row.PlayUrl"><a href="{{row.PlayUrl}}" target="store">Google Play</a>&nbsp;</span>
        <span *ngIf="row.RhapsodyUrl"><a href="{{row.RhapsodyUrl}}" target="store">Rhapsody</a>&nbsp;</span>
        <span *ngIf="row.SpotifyUrl"><a href="{{row.SpotifyUrl}}" target="store">Spotify</a>&nbsp;</span>
        <span *ngIf="row.YouTubeUrl"><a href="{{row.YouTubeUrl}}" target="store">YouTube</a>&nbsp;</span>
      </td>
    </ng-container>

    <!-- Actions Column -->
    <ng-container matColumnDef="Actions">
        <th mat-header-cell *matHeaderCellDef> Actions </th>

        <td mat-cell *matCellDef="let row">
            <button mat-button (click)="editSound(row)"><i class="material-icons action-edit">mode_edit</i></button><br>
            <button mat-button (click)="attachSound(row)"><i class="material-icons action-attach">attach_file</i></button><br>
            <button mat-button (click)="deleteSound(row)"><i class="material-icons action-delete">delete</i></button><br>
        </td>
    </ng-container>

    <!-- Downloads Column -->
    <ng-container matColumnDef="Download">
        <th mat-header-cell *matHeaderCellDef> Files </th>

        <td mat-cell *matCellDef="let row">
          <div *ngIf="row.WhiteNoiseUrl"><a href="{{getAdminDownloadUrl(row, 'wna')}}" download="{{row.Slug}}.wna"> wna </a> </div>
          <div><a href="{{getAdminDownloadUrl(row, 'plist')}}" download="{{row.Slug}}.plist"> plist </a> </div>
          <div><a href="{{getAdminDownloadUrl(row, 'jpg')}}" download="{{row.Slug}}.jpg"> jpg </a> </div>
          <div *ngIf="row.PreviewUrl"><a href="{{getAdminDownloadUrl(row, 'mp3')}}" download="{{row.Slug}}.mp3"> mp3 </a></div>
          <div *ngIf="row.WhiteNoiseUrl"><a href="{{getAdminDownloadUrl(row, 'wnd')}}" download="{{row.Slug}}.wnd"> wnd </a> </div>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-progress-bar mode="indeterminate" *ngIf="working"></mat-progress-bar>
</div>

<!-- <div class="page-buttons" *ngIf="!working && (curOffset > 0 || soundCount >= curLimit)">  -->
<div class="page-buttons" *ngIf="!working">
  <button mat-raised-button (click)="pageSounds(false)" [disabled]="curOffset == 0">Prev</button>
  <button mat-raised-button (click)="pageSounds(true)" [disabled]="soundCount < curLimit">Next</button>
</div>
