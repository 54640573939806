hr { 
    border: 1px solid #DADADA; 
    border-bottom-width: 0;
}

.container {
    width:100%;
}

.radio-cell {
    display: inline-block;
    font-size: 100%;
    text-align: center;
    width: 16px;
}

.radio-label {
    display: inline-block;
    margin-left: 32px;
}

.message {
    padding: 3px 8px;
    background-color: #FEE;
    font-size: 80%;
    font-weight: 500;
}