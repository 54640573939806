<mat-progress-bar mode="indeterminate" *ngIf="working"></mat-progress-bar>      
<span *ngIf="showLogin">
  <div style="width: 100%; text-align: center;">
    <p>Logging in {{soundService.displayName}}...</p>
  </div>
</span>
<span *ngIf="showDenied">
  <div style="width: 100%; text-align: center;">
    <img src="/assets/skull-n-bones.png" height="400" />
    <br/>   
    <div style="font-size: 200%">Access Denied!</div>
    <br/>
    <br/>
    <a href="{{this.config.host()}}">Go Back</a>
  </div>
</span>
<span *ngIf="showDashboard">  
  
  <div style="width: 100%; text-align: center;">    
    <mat-toolbar>
      <span>Overall</span>
    </mat-toolbar>
    <ngx-charts-number-card
      [view]="view"
      [scheme]="colorScheme"
      [results]="overall"
      [cardColor]="cardColor">
    </ngx-charts-number-card>    
  </div>

  <div style="width: 100%; text-align: center;">    
    <mat-toolbar>
      <span>Users</span>
    </mat-toolbar>
    <ngx-charts-line-chart
      [scheme]="colorScheme"
      [results]="users"
      [timeline]="timeline"
      [gradient]="gradient"
      [xAxis]="showXAxis"
      [yAxis]="showYAxis"
      [legend]="showLegend"
      [showXAxisLabel]="showXAxisLabel"
      [showYAxisLabel]="showYAxisLabel"
      [xAxisLabel]="xAxisLabel"
      [yAxisLabel]="yAxisLabel">
    </ngx-charts-line-chart>
  </div>

  <mat-toolbar>
    <span>Downloads</span>
  </mat-toolbar>
  <div style="width: 100%; text-align: center;">    
    <ngx-charts-line-chart
      [scheme]="colorScheme"
      [results]="downloads"
      [timeline]="timeline"
      [gradient]="gradient"
      [xAxis]="showXAxis"
      [yAxis]="showYAxis"
      [legend]="showLegend"
      [showXAxisLabel]="showXAxisLabel"
      [showYAxisLabel]="showYAxisLabel"
      [xAxisLabel]="xAxisLabel"
      [yAxisLabel]="yAxisLabel">
    </ngx-charts-line-chart>
  </div>

  <mat-toolbar>
    <span>Uploads</span>
  </mat-toolbar>
  <div style="width: 100%; text-align: center;">    
    <ngx-charts-line-chart
      [scheme]="colorScheme"
      [results]="uploads"
      [timeline]="timeline"
      [gradient]="gradient"
      [xAxis]="showXAxis"
      [yAxis]="showYAxis"
      [legend]="showLegend"
      [showXAxisLabel]="showXAxisLabel"
      [showYAxisLabel]="showYAxisLabel"
      [xAxisLabel]="xAxisLabel"
      [yAxisLabel]="yAxisLabel">
    </ngx-charts-line-chart>
  </div>

  <mat-toolbar>
    <span>Comments</span>
  </mat-toolbar>
  <div style="width: 100%; text-align: center;">    
    <ngx-charts-line-chart
      [scheme]="colorScheme"
      [results]="comments"
      [timeline]="timeline"
      [gradient]="gradient"
      [xAxis]="showXAxis"
      [yAxis]="showYAxis"
      [legend]="showLegend"
      [showXAxisLabel]="showXAxisLabel"
      [showYAxisLabel]="showYAxisLabel"
      [xAxisLabel]="xAxisLabel"
      [yAxisLabel]="yAxisLabel">
    </ngx-charts-line-chart>
  </div>

  <mat-toolbar>
    <span>Hearts</span>
  </mat-toolbar>
  <div style="width: 100%; text-align: center;">    
    <ngx-charts-line-chart
      [scheme]="colorScheme"
      [results]="hearts"
      [timeline]="timeline"
      [gradient]="gradient"
      [xAxis]="showXAxis"
      [yAxis]="showYAxis"
      [legend]="showLegend"
      [showXAxisLabel]="showXAxisLabel"
      [showYAxisLabel]="showYAxisLabel"
      [xAxisLabel]="xAxisLabel"
      [yAxisLabel]="yAxisLabel">
    </ngx-charts-line-chart>
  </div>

  <mat-toolbar>
    <span>Subscriptions</span>
  </mat-toolbar>
  <div style="width: 100%; text-align: center;">    
    <ngx-charts-line-chart
      [scheme]="storeScheme"
      [results]="subscriptions"
      [timeline]="timeline"
      [gradient]="gradient"
      [xAxis]="showXAxis"
      [yAxis]="showYAxis"
      [legend]="showLegend"
      [showXAxisLabel]="showXAxisLabel"
      [showYAxisLabel]="showYAxisLabel"
      [xAxisLabel]="xAxisLabel"
      [yAxisLabel]="yAxisLabel">
    </ngx-charts-line-chart>
  </div>

  <mat-toolbar>
    <span>Logs</span>
  </mat-toolbar>
  <div style="width: 100%; text-align: center;">    
    <ngx-charts-line-chart
      [scheme]="logScheme"
      [results]="logs"
      [timeline]="timeline"
      [gradient]="gradient"
      [xAxis]="showXAxis"
      [yAxis]="showYAxis"
      [legend]="showLegend"
      [showXAxisLabel]="showXAxisLabel"
      [showYAxisLabel]="showYAxisLabel"
      [xAxisLabel]="xAxisLabel"
      [yAxisLabel]="yAxisLabel">
    </ngx-charts-line-chart>
  </div>
</span>