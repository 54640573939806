<mat-toolbar>
    <span>{{title}}</span>
    <span class="app-toolbar-filler"></span>
    <button mat-button (click)="openAddTagDialog()"><i class="material-icons">add</i> Add </button>    
</mat-toolbar>

<div class="database-container">
    <mat-table #table [dataSource]="dataSource">
  
      <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->
  
      <!-- Image Column -->
      <ng-container matColumnDef="Image">
        <mat-header-cell *matHeaderCellDef>Image</mat-header-cell>
        <mat-cell *matCellDef="let row"> 
          <div *ngIf="row.ImageUrl"><a href="{{this.config.host()}}/tag/{{row.Value}}" target="market"><img [src]="row.ImageUrl" width=64 height=64></a>
          </div>
          <div *ngIf="!row.ImageUrl">(default)</div>
        </mat-cell>
      </ng-container>
  
      <!-- Label Column -->
      <ng-container matColumnDef="TagLabel">
        <mat-header-cell *matHeaderCellDef>Label</mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.Label}} </mat-cell>
      </ng-container>
  
      <!-- Tag Value Column -->
      <ng-container matColumnDef="TagValue">
        <mat-header-cell *matHeaderCellDef>Value</mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.Value}} </mat-cell>
      </ng-container>
  
      <!-- Sort Column -->
      <ng-container matColumnDef="Sort">
        <mat-header-cell *matHeaderCellDef>Sort</mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.Sort | number}} </mat-cell>
      </ng-container>

      <!-- Description Column -->
      <ng-container matColumnDef="Description">
        <mat-header-cell *matHeaderCellDef>Description</mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.Description}} </mat-cell>
      </ng-container>
    
      <!-- Actions Column -->
      <ng-container matColumnDef="Actions">
            <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
            <mat-cell *matCellDef="let row">
                <button mat-button (click)="editTag(row)"><i class="material-icons action-edit">mode_edit</i></button><br>                
                <button mat-button (click)="deleteTag(row)"><i class="material-icons action-delete">delete</i></button><br>
            </mat-cell>
      </ng-container>  
  
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
    <mat-progress-bar mode="indeterminate" *ngIf="working"></mat-progress-bar>
  </div>
  