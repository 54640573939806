<h2 mat-dialog-title>
    Edit Sound
</h2>
<mat-dialog-content>
    <div class="dialog-column">
        <div>
            <mat-form-field>
                <mat-label>UID</mat-label>
                <input readonly matInput value="" [(ngModel)]="sound.Uid">
            </mat-form-field>
        </div>
        <div>
            <mat-form-field>
                <mat-label>Label</mat-label>
                <input matInput value="" [(ngModel)]="sound.Label" required>
            </mat-form-field>
        </div>
        <div>
            <mat-form-field>
                <mat-label>Title</mat-label>
                <input matInput value="" [(ngModel)]="sound.Title" required>
            </mat-form-field>
        </div>
        <div class="flex-stretch">
            <mat-form-field>
                <mat-label>Description</mat-label>
                <textarea matInput rows="5" value="" [(ngModel)]="sound.Description" required></textarea>
            </mat-form-field>
        </div>  
        <div>
            <mat-form-field>
                <mat-label>Tags</mat-label>
                <input matInput value="" [(ngModel)]="sound.Tags">
            </mat-form-field>
        </div>    

    </div>
    <div class="dialog-column">
        <div>
            <mat-form-field>
                <mat-label>Latitude</mat-label>
                <input matInput value="" [(ngModel)]="sound.Location.Lat">
            </mat-form-field>
        </div>
        <div>
            <mat-form-field>
                <mat-label>Longitude</mat-label>
                <input matInput value="" [(ngModel)]="sound.Location.Lng">
            </mat-form-field>
        </div>
 
        <div>
            <mat-form-field>
                <mat-label>iTunes URL</mat-label>
                <input matInput value="" [(ngModel)]="sound.ITunesUrl">
            </mat-form-field>
        </div>        
        <div>
            <mat-form-field>
                <mat-label>Spotify URL</mat-label>
                <input matInput value="" [(ngModel)]="sound.SpotifyUrl">
            </mat-form-field>
        </div>        
        <div>
            <mat-form-field>
                <mat-label>YouTube Music URL</mat-label>
                <input matInput value="" [(ngModel)]="sound.PlayUrl">
            </mat-form-field>
        </div>         
        <div>
            <mat-form-field>
                <mat-label>Amazon URL</mat-label>
                <input matInput value="" [(ngModel)]="sound.AmazonUrl">
            </mat-form-field>
        </div>      
        <div>
            <mat-form-field>
                <mat-label>YouTube URL</mat-label>
                <input matInput value="" [(ngModel)]="sound.YouTubeUrl">
            </mat-form-field>
        </div>

                
        <div>
            <mat-form-field>
                <input matInput type="number" placeholder="State:" value="" [(ngModel)]="sound.State">
            </mat-form-field>
            <p class="state-help-list">
                0=Published 1=Tagged 2=Featuring 5=Overflow 10=Admin 11=Special 14=High Priority 15=Low Priority 16=Edit Sound 17=Edit Photo 19=Disapproved 30=Private 99=Blocked
            </p>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="onSubmit()">Save</button>
    <button mat-raised-button [mat-dialog-close]="false" color="warn">Cancel</button>
</mat-dialog-actions>
